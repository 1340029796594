import React, {useState, useEffect, createContext} from 'react';
import {ThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import DayjsUtils from '@date-io/dayjs';
import Login from './Login';
import Recycle from './Recycle';
import Base from './Base';
import AppTheme from './theme';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {SnackbarProvider, useSnackbar} from 'notistack';

const theme = createMuiTheme(AppTheme);

export const AppConfigContext = createContext({});
export const SharedStateContext = createContext({});

const App = () => {
    const [appConfig, setAppConfig] = useState({
        setState: (state) => {
            setAppConfig(state);
        },
        authenticated: false,
        sites: [],
        pages: [],
        menu: [],
        user: null,
        rpc_loading: false,
        initialized: false,
        pendingRpcCalls: 0
    });

    const [sharedState, setSharedState] = useState({
        setState: (state) => {
            setSharedState(state)
        }
    });

    const loadSitePreference = () => {
        try {
            const preferredSite = localStorage.getItem('preferredSite');
            if (preferredSite === null){
                return undefined;
            }
            return `sites:${preferredSite}`;
        } catch (err) {
            return undefined;
        }
    };

    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        Recycle.onRpcError((err) => {
            if (err.response.status === 401) {
                Recycle.logout();
                setAppConfig({...appConfig, authenticated: false, initialized: false, user: null});
            }
        });

        Recycle.pendingRpcCalls.subscribe((count) => {
            setAppConfig((oldConfig) => {
                return {...oldConfig, pendingRpcCalls: count}
            });
        });

        if (Recycle.isAuthenticated()) {
            setAppConfig({...appConfig, authenticated: true});
        }
    }, []);


    const classes = useStyles();

    useEffect(() => {
        if (appConfig.authenticated) {
            const accessToken = Recycle.authProvider.getAccessToken();
            const idToken = Recycle.authProvider.getIdToken();
            const user = Recycle.authProvider.getUser();
            let errorMessage = "User does not have access to the OccHealth system"
            let sites = [];
            let preferredSite = loadSitePreference();

            try {
                if (user.groups.indexOf(process.env.REACT_APP_USER_GROUP) < 0) {
                    throw Error();
                }
                console.log(user['roles'])
                const rolesArray = user['roles']
                rolesArray.forEach((value) => {
                    if (value.startsWith("sites:")) {
                        user.dsp = value.split(':')[1]; // uses value for current site
                        sites.push(value.split(':')[1]);
                    } else {
                        user.role = value
                    }
                })

                if (preferredSite !== undefined && rolesArray.includes(preferredSite)){
                    user.dsp = preferredSite.split(':')[1]; // uses value for current site
                }
                else if (preferredSite !== undefined){
                    localStorage.removeItem('preferredSite')
                }

                if (user.dsp === undefined) {
                    errorMessage = "Your user does not belong to any occhealth sites."
                    throw Error()
                }
                if (user.role === undefined) {
                    errorMessage = "User does not have any roles";
                    throw Error()
                }

            } catch (err) {
                Recycle.logout();
                enqueueSnackbar(errorMessage, {variant: "error"});
                setAppConfig({...appConfig, authenticated: false});
                return;
            }
            
            Recycle.setAuth('Authorization', accessToken);
            Recycle.setHeader('Identity', idToken);
            Recycle.fetchAppConfig().then(config => {
                Recycle.rpc({
                    model: "Dsp",
                    method: "get_multi_site_details",
                    args: [sites]
                }).then(dsps => {
                    if (!dsps.hasOwnProperty(user.dsp)){
                        const errorMessage = `Failed to get DSP details, ${user.dsp}`
                        enqueueSnackbar(errorMessage, { variant: "error" });
                        if (Object.keys(dsps).length > 0) {
                            localStorage.removeItem('preferredSite');
                            user.dsp = dsps[Object.keys(dsps)[0]].dsp_reference
                        }
                    }
                    let dsp_details = dsps[user.dsp];
                    setAppConfig({ ...appConfig, ...config, sites: Object.values(dsps), user, dsp_details, initialized: true });
                }).catch((err) => {
                    Recycle.logout();
                    const errorMessage = err.response?.data ? err.response.data : "Failed to get DSP details"
                    enqueueSnackbar(errorMessage, { variant: "error" });
                    setAppConfig({ ...appConfig, authenticated: false });
                    return;
                })
            });
        }
    }, [appConfig.authenticated]);

    const renderApp = () => {
        if (appConfig.authenticated && appConfig.initialized) {
            return <Base pages={appConfig.pages} menu={appConfig.menu}/>
        } else if (!appConfig.authenticated) {
            return <Login/>
        }
    }

    return (
        <AppConfigContext.Provider value={appConfig}>
            <SharedStateContext.Provider value={sharedState}>
                <>
                    <Backdrop className={classes.backdrop} open={appConfig.pendingRpcCalls > 0}>
                        <CircularProgress color="inherit"/>
                    </Backdrop>
                    {renderApp()}
                </>
            </SharedStateContext.Provider>
        </AppConfigContext.Provider>
    )
}

const RootApp = () => {
    return (
        <MuiPickersUtilsProvider utils={DayjsUtils}>
            <ThemeProvider theme={theme}>
                <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
                    <App/>
                </SnackbarProvider>
            </ThemeProvider>
        </MuiPickersUtilsProvider>
    )
}

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 999,
        color: '#cbc5c5',
        backdropFilter: 'blur(5px)'

    },
}));


export default RootApp;
