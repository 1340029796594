import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from 'react-router-dom';
import InputLabel from "@material-ui/core/InputLabel";
import Select from "react-select";
import Button from "@material-ui/core/Button";
import { sharedStyles } from "../../shared/styles";
import Recycle from "../../Recycle";

const AgendaSearch = ({config, props}) => {
    const history = useHistory();
    const classes = useStyles();
    const sharedClasses = sharedStyles();
    const [site, setSite] = useState(null);
    const [role, setRole] = useState(null);

    const handleProceed = () => {
        Recycle.rpc({
            model: "AgendaManager",
            method: "get_employee",
            args:[props.patient_identifier]
        }).then(employee => {
            if (employee.employment_site_id === site.value && employee.employee_role_id === role.value) {
                createConsultDetails(`/agenda-events/${props.consult_id}/${props.patient_identifier}`);
            }
            else {
                createConsultDetails("/Dashboard");
            }
        }).catch(err => {
            if (err.response.status === 404) {
                createConsultDetails("/Dashboard");
            }
        });
    }

    const createConsultDetails = (redirectPath) => {
        Recycle.rpc({
            model: "AgendaManager",
            method: "create_consult_details",
            args: [props.patient_identifier, props.consult_id, site.value, role.value]
        }).then(res => {
            history.push(redirectPath);
        })
    }

    return (
        <div className={classes.container}>
            <div className={sharedClasses.subheading}>Please confirm the employee's site and role</div>
            <div className={classes.inputContainer}>
                <div>
                    <InputLabel shrink={true}>Employment Site</InputLabel>
                    <Select value={site} onChange={setSite} options={props.sites}/>
                </div>
                
                <div>
                    <InputLabel shrink={true}>Employee Role</InputLabel>
                    <Select value={role} onChange={setRole} options={props.roles} />
                </div>
            </div>
            <Button className={classes.proceedButton} variant="contained" color="primary" onClick={handleProceed} disabled={!site || !role}>proceed</Button>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    container: {
        display: "grid",
        gridAutoFlow: "row",
        gap: theme.spacing(3)
    },
    inputContainer: {
        display: "grid",
        gridAutoFlow: "column",
        gap: theme.spacing(3)
    },
    proceedButton: {
        justifySelf: "end"
    }
}));

export default AgendaSearch;