import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import {DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DynamicForm from "../../DynamicForm";

const AddNewBatchDialog = props => {
    const submitNewBatch = (values) => {
        props.createNewBatch(values)
        props.handleClose();

    }
    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle id="form-dialog-title">Create New Batch</DialogTitle>
            <DialogContent>
                <DynamicForm onSubmit={submitNewBatch}
                             config={{fields: manual_booking_details, defaultValues: {}}}
                             actions={[
                                 ({submitting}) => <Button
                                     key="cancel"
                                     disabled={submitting}
                                     onClick={() => props.handleClose()}
                                     variant="outlined">
                                     Cancel
                                 </Button>,
                                 ({submitting}) => (<Button
                                     key="save"
                                     variant="contained"
                                     color="primary"
                                     type="submit"
                                     disabled={submitting}
                                 >
                                     Create
                                 </Button>)
                             ]}
                />
            </DialogContent>
        </Dialog>
    );
};


export default AddNewBatchDialog;

let manual_booking_details = {
    "id": "BATCH_DETAILS",
    "name": "Batch Details",
    "groups": [
        {
            "id": "part_1",
            "name": "Enter Batch Details Below",
            "fields": [
                {
                    "id": "batch_number",
                    "name": "Batch Number",
                    "type": "text",
                    "default": "None",
                    "optional": false,
                    "display_view": "text"
                },
                {
                    "id": "expiry_date",
                    "name": "Expiry Date",
                    "type": "date",
                    "default": "2021/01/01",
                    "optional": false,
                    "display_view": "text",
                    "validation": [
                        "!=",
                        "get", "expiry_date",
                        "undefined"
                    ],
                    "render_condition": [],
                    "calculation_precondition": [],
                    "calculation": [],
                    "options": []
                },
            ]
        }
    ]
}