import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import dayjs from 'dayjs';

const ArrivalCell = ({ consult }) => {
    const classes = useStyles();

    return (
        <div className={classes.time}>
            {dayjs(consult.arrived_at).format('hh:mm A')}
        </div>
    )
};

const useStyles = makeStyles((theme) => ({
    time: {
        fontSize: '11px',
        color: '#666666',
        lineHeight: '15px'
    }
}));

export default ArrivalCell;