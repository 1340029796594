import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Divider from '@material-ui/core/Divider';
import InfoText from '../InfoText';
import { calculateAge } from '../../shared/utils';
import { sharedStyles } from '../../shared/styles';

const AgendaPatientDetails = ({ config, props }) => {
    const classes = useStyles();
    const sharedClasses = sharedStyles();

    return (
        <div>
            <div className={classes.top}>

                <div className={classes.details}>
                    <div
                        className={sharedClasses.heading}>{props.patient.first_name} {props.patient.last_name}</div>
                    <div
                        className={clsx([classes.identifier, sharedClasses.helperText])}>ID: {props.patient.identifier}
                    </div>

                </div>
            </div>

            <Divider className={classes.divider} variant="middle" />

            <div className={classes.rightContainer}>
                <BasicInformation patient={props.patient} />
            </div>
        </div>
    )
};

const BasicInformation = ({ patient }) => {
    const classes = useStyles();

    return (
        <div>
            <div className={classes.heading}>Basic Information</div>

            <div className={classes.infoContainer}>
                <InfoText className={classes.infoText} placeholder="Gender" capitalize={true} text={patient.gender} />
                <InfoText className={classes.infoText} placeholder="Age" text={calculateAge(patient.date_of_birth)} />
                <InfoText className={classes.infoText} placeholder="Contact No." text={patient.contact_number} />
                <InfoText className={classes.infoText} placeholder="Email ID" text={patient.email} />
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    top: {
        display: 'flex',
        textTransform: 'capitalize'
    },
    details: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        alignItems: 'center',
        flex: 1,
        marginLeft: theme.spacing(2)
    },
    profileImage: {
        height: '82px',
    },
    identifier: {
        textAlign: 'end'
    },
    heading: {
        color: theme.palette.primary.main,
        fontSize: '16px',
        fontWeight: 'bold',
        marginBottom: theme.spacing(3)
    },
    infoText: {
        padding: theme.spacing(3),
        '&:not(:first-child)': {
            borderLeft: '1px solid #EEEEEE'
        }
    },
    infoContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    service: {
        display: 'flex',
    }
}));

export default AgendaPatientDetails;