import React, {useContext, useState} from 'react';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import {useHistory} from 'react-router-dom'
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import Recycle from '../../Recycle';
import MaterialDivider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import CircularProgress from '@material-ui/core/CircularProgress';

import FileUploader from "./FileUploader";
import {
    ButtonContainer,
    ColorlibConnector,
    ColorlibStepIcon,
    ContainerPaper,
    GreyPaperContainer,
    SMSTextContainer,
} from "./Styling";
import Dialog from "@material-ui/core/Dialog";
import TextField from '@material-ui/core/TextField';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import {Form} from "react-final-form";
import {useSnackbar} from 'notistack';
import DialogActions from "@material-ui/core/DialogActions";

const RegisterPatientConsent = ({config, props}) => {
    const classes = useStyles();
    const history = useHistory();
    const [activeStep, setActiveStep] = React.useState(0);
    const [patientNumber, setPatientNumber] = React.useState(null);
    const [displayNumber, setDisplayNumber] = React.useState(props.patient.contact_number);
    const [errorText, setErrorText] = React.useState(null);
    const [submitting, setSubmitting] = React.useState(false);
    const steps = getSteps();
    const [openUpdateNumberPopup, setOpenUpdateNumberPopup] = useState(false);
    const {enqueueSnackbar} = useSnackbar();


    React.useEffect(() => {
        triggerConsentSMS();

        const timer = setInterval(() => {
            Recycle.rpc({
                model: "BookingController",
                method: "check_patient_accepted_terms_and_conditions",
                args: [props.booking.booking_id]
            }, false).then((res) => {
                if (res.accepted_terms_and_conditions === true) {
                    setActiveStep(2)
                    clearInterval(timer);
                }
            }).catch((err) => {

            })
        }, 2500);

        return () => {
            clearInterval(timer);
        };

    }, []);

    const triggerConsentSMS = () => {
        Recycle.rpc({
            model: "PatientController",
            method: "trigger_terms_and_conditions_consent_sms",
            args: [props.patient.identifier_type, props.patient.identifier, props.booking.booking_id]
        }).then((res) => {
        }).catch((err) => {

        })
    }

    function getSteps() {
        return ['Receive an SMS on patient\'s phone', 'Agree to the terms of services & reply \'Yes\'', 'Successfully Registered'];
    }

    const handleSubmitUpdateNumber = async () => {
        setErrorText(null);
        setSubmitting(true);
        if (patientNumber === null || patientNumber.length > 12 || patientNumber.length < 10) {
            setErrorText("Incorrect number format");
            setSubmitting(false);
            return
        }
        await Recycle.rpc({
            model: "PatientController",
            method: "update_patient_contact_number",
            args: [props.patient.identifier, props.patient.identifier_type, patientNumber]
        }).then((res) => {
            setOpenUpdateNumberPopup(false);
            setDisplayNumber(res.contact_number);
            triggerConsentSMS();
        }).catch((err) => {
            if (err.response.status === 422) {
                setErrorText("Incorrect number format");
            }
            else {
                setErrorText("Unknown error");
            }
        })
        setSubmitting(false);
    }

    const handleCloseUpdateNumber = () => {
        setOpenUpdateNumberPopup(false)
    }

    const handleProceed = () => {
        Recycle.rpc({
            model: "BookingController",
            method: "update_booking_status",
            args: ["arrived", props.booking.booking_id]
        }).then((res) => {
            Recycle.rpc({
                model: "ConsultController",
                method: "create_consult",
                args: [props.booking.dsp_reference, props.booking.booking_id, props.patient.identifier_type, props.patient.identifier]
            }).then((res) => {
                enqueueSnackbar("Registration Successful", {variant: "success"});
                if(props.dsp_details && props.dsp_details.is_agenda_site) {
                    const patient_identifier = `${props.patient.identifier_type}_${props.patient.identifier}`;
                    history.push(`/agenda-search/${res.consult_id}/${patient_identifier}`);
                }
                else {
                    history.push("/dashboard");
                }
            }).catch((err) => {
                enqueueSnackbar("Failed to create consult", {variant: "error"});
            })

        }).catch((err) => {
            enqueueSnackbar("Failed to update booking status", {variant: "error"});
        })
    }


    return (
        <React.Fragment>
            <ContainerPaper>

                <div className={classes.title}>Patient Consent</div>
                <Typography>Please confirm Terms of Service before proceeding</Typography>
                <GreyPaperContainer square={false}>
                    <SMSTextContainer>
                        <p> SMS Sent to</p>
                        <p> {props.patient.first_name} {props.patient.last_name} | {displayNumber}</p>
                        {activeStep < 2 && <CircularProgress/>}
                    </SMSTextContainer>

                </GreyPaperContainer>
                <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector/>}>
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={ColorlibStepIcon}>Step {index + 1} {label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <MaterialDivider variant="middle"/>

                <h5> Try Manual Process</h5>
                <div style={{paddingBottom: '10px'}}>
                    <div>
                        <GetAppIcon/>
                        <Link
                            href="https://occhealthdistributed-rpcapi-dev-core-rpc-bucket.s3-us-west-2.amazonaws.com/Netcare-Terms-and-Conditions.pdf"
                            target="_blank"
                            rel="noreferrer">
                            Downloads Terms and Conditions for Signature
                        </Link></div>


                </div>
                <FileUploader patient_identifier={`${props.patient.identifier_type}_${props.patient.identifier}`} booking_id={props.booking.booking_id}/>

                <ButtonContainer>
                    <Button variant="contained" color="default" onClick={() => setOpenUpdateNumberPopup(true)}>
                        Update Contact
                    </Button>
                    <Button variant="contained" onClick={handleProceed} color="primary" disabled={activeStep !== 2}>Proceed
                        to
                        Confirm</Button>
                </ButtonContainer>


            </ContainerPaper>

            {/*TODO: Update the contact method change*/}
            <Dialog fullWidth={true} maxWidth={"sm"} open={openUpdateNumberPopup} onClose={handleCloseUpdateNumber}>
                <DialogTitle id="form-dialog-title">Update Contact Number</DialogTitle>
                <DialogContent>
                    <TextField
                        onChange={(e) => setPatientNumber(e.target.value)}
                        margin="normal"
                        error={!!errorText}
                        helperText={errorText}
                        type="number"
                        id="contact-number"
                        label="Contant Number"
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCloseUpdateNumber} color="primary">
                    Cancel
                </Button>
                <Button disabled={submitting} onClick={handleSubmitUpdateNumber} color="primary" variant="contained">
                    {submitting ? <CircularProgress size={20} /> : "Update Number"}
                </Button>
            </DialogActions>
            </Dialog>

        </React.Fragment>
    )
}

const useStyles = makeStyles(theme => ({
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-center',
        marginTop: theme.spacing(2)
    },
    title: {
        color: theme.palette.primary.main,
        fontSize: '16px',
        fontWeight: 'bold'
    },
    smsText: {
        padding: '5px',
        backgroundColor: '#f8f3f3'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4, 8, 6),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    printAcknowledgement: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    },
    divider: {
        width: '65%'
    },
    checkIcon: {
        color: theme.palette.success.main
    }
}));


export default RegisterPatientConsent;