export const appointmentStatusColorMapping = (theme) => ({
    pending: theme.palette.warning,
    upcoming: theme.palette.warning,
    cancelled: theme.palette.error,
    arrived: theme.palette.success,
    completed: theme.palette.success,
    no_show: theme.palette.error,
    incoming: theme.palette.warning,

});

export const consultStatusColorMapping = (theme) => ({
    not_started: theme.palette.error,
    in_progress: theme.palette.warning,
    completed: theme.palette.success,
    expired: theme.palette.error,
    procedures_completed: theme.palette.error,
    awaiting_omp: theme.palette.error,
});

export const procedureIconMapping = {
    medical_history: 'medical_services',
    family_history: 'family_restroom',
    urine_analysis: 'science',
    physical_examination: 'accessibility',
    fatigue_management: 'favorite',
    eye_test: 'visibility'
};

export const procedureStatusColorMapping = (theme) => ({
    pending: theme.palette.warning,
    completed: theme.palette.success
});