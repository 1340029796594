import Recycle from "./Recycle";

export const getStockItems = async (site_id, sort_by_category) => {
    return await Recycle.rpc({
        model: "StockManagement",
        method: "get_stock_items_for_site",
        args: [site_id, sort_by_category]
    }).then((res) => {
        return res
    }).catch((err) => {
        return []
    })


}

export const getTransferStockItems = async (site_id, site_id_transfer_out, transfer_id) => {
    return await Recycle.rpc({
        model: "StockManagement",
        method: "fetch_stock_transfer",
        args: [site_id, site_id_transfer_out, transfer_id]
    }).then((res) => {
        return res
    }).catch((err) => {
        return {}
    })


}

export const getGRVStockMoves = async (site_id, sort_by_category) => {
    return await Recycle.rpc({
        model: "StockManagement",
        method: "get_grv_stock_moves_for_site",
        args: [site_id, sort_by_category]
    }).then((res) => {
        return res
    }).catch((err) => {
        return []
    })


}

export const getStockMoves = async (site_id, sort_by_category, type) => {
    return await Recycle.rpc({
        model: "StockManagement",
        method: "get_stock_moves_for_site",
        args: [site_id, sort_by_category, type]
    }).then((res) => {
        return res
    }).catch((err) => {
        return []
    })


}

export const saveStockReceived = async (site_id, good_received) => {
    return await Recycle.rpc({
        model: "StockManagement",
        method: "goods_received",
        args: [site_id, good_received]
    }).then((res) => {
        return true
    }).catch((err) => {
        return false
    })
}

export const saveStockReceivedTransferIn = async (site_id, good_received, site_id_transfer_out, transfer_id) => {
    return await Recycle.rpc({
        model: "StockManagement",
        method: "goods_received_stock_transfer_in",
        args: [site_id, good_received, site_id_transfer_out, transfer_id]
    }).then((res) => {
        return true
    }).catch((err) => {
        return false
    })
}

export const getCurrentStockItems = async (site_id, sort_by_category) => {
    return await Recycle.rpc({
        model: "StockManagement",
        method: "get_stock_items_for_site",
        args: [site_id, sort_by_category]
    }).then((res) => {
        return res
    }).catch((err) => {
        return []
    })

}

export const saveStockTake = async (site_id, stock_items) => {
    return await Recycle.rpc({
        model: "StockManagement",
        method: "stock_take",
        args: [site_id, stock_items]
    }).then((res) => {
        return true
    }).catch((err) => {
        return false
    })
}

export const recordStockTransferOut = async (site_id, payload) => {
    return await Recycle.rpc({
        model: "StockManagement",
        method: "record_stock_transfer_out",
        args: [site_id, payload]
    }).then((res) => {
        return [true, res]
    }).catch((err) => {
        return [false, null]
    })
}

export const recordDamages = async (site_id, payload) => {
    return await Recycle.rpc({
        model: "StockManagement",
        method: "record_damages",
        args: [site_id, payload]
    }).then((res) => {
        return [true, res]
    }).catch((err) => {
        return [false, null]
    })
}

export const GetClinicsTransferIn = async (site_id) => {
    return await Recycle.rpc({
        model: "StockManagement",
        method: "get_available_stock_transfers",
        args: [site_id]
    }).then((res) => {
        return res
    }).catch((err) => {
        return null
    })


}