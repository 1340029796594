import React from 'react';
import {Field} from 'react-final-form';
import {DateTimePicker} from 'mui-rff';
import FieldLayout from "./FieldLayout";

const DateTimeField = ({id, name, optional, initial, description, warning}) => {
    let label = name;
    return (
        <FieldLayout description={description} warning={warning}>
            <Field name={id}
                   placeholder={label}
                   optional={optional}
                   initial={initial}>
                {
                    (fieldProps) => {
                        return <DateTimePicker
                            style={{width: "100%"}}
                            value={fieldProps.input.value}
                            name={fieldProps.input.name}
                            defaultValue={initial}
                            margin="none"
                            label={fieldProps.placeholder}
                            onChange={fieldProps.input.onChange}
                            required={optional === false}
                            format="DD/MM/YYYY hh:mm:ss A"
                        />
                    }
                }
            </Field>
        </FieldLayout>
    )
}

export default DateTimeField;