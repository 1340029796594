import React from 'react';
import {Field} from 'react-final-form';
import FieldLayout from './FieldLayout'
import InputLabel from "@material-ui/core/InputLabel";

import NumericInput from 'react-numeric-input';

NumericInput.style.btn = {
    position: 'absolute',
    right: 2,
    width: '2.26ex',
    textAlign: 'center',
    cursor: 'default',
    transition: 'all 0.1s',
    boxShadow: '-1px -1px 3px rgba(0,0,0,.1) inset,' +
        '1px 1px 3px rgba(255,255,255,.7) inset'
}

NumericInput.style['input:not(.form-control)'] = {
    border: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    marginBottom: 1,
    transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderRadius: 2,
    paddingLeft: 4,
    display: 'block',
    WebkitAppearance: 'none',
    lineHeight: 'normal',
}

NumericInput.style['input:focus'] = {
    borderBottom: '2px solid rgba(0, 0, 0, 0.87)',
    marginBottom: 0,
    outline: 'none'
}

NumericInput.style.input = {
    ...NumericInput.style.input,
    width: '100%',
    paddingTop: 10,
    paddingBottom: 10,
}

NumericInput.style.wrap = {
    ...NumericInput.style.wrap,
    width: '100%',
    marginTop: 5,
    fontSize: 16,
    border: 'none',
}


const NumericInputField = ({id, name, optional, options, unit, description, warning, numeric_config, readOnly=false}) => {

    const config = numeric_config || {};
    const {min = Number.MIN_SAFE_INTEGER, max = Number.MAX_SAFE_INTEGER, step = 1, precision = null} = config;

    return (
        <FieldLayout description={description} warning={warning}>
            <Field name={id}
                   label={name}
                   optional={optional}
                   adornment={unit ? ` (${unit})` : ""}>
                {props => (
                    <>
                        <InputLabel shrink={true}>{name + (unit ? ` (${unit})` : '') + (optional ? '' : ' *')}</InputLabel>

                        <NumericInput
                            min={min}
                            max={max}
                            step={step}
                            precision={precision}
                            value={props.input.value}
                            onChange={newValue => props.input.onChange(newValue)}
                            onBlur={() => {
                                if (readOnly)
                                    return
                                if (props.input.value === "")
                                    return

                                if (props.input.value < min)
                                    props.input.onChange(min)
                                else if (props.input.value > max)
                                    props.input.onChange(max)
                            }}
                            inputmode="numeric"
                            readOnly={readOnly}
                            format={unit ? num => `${num} ${unit}`: null}
                            // mobile
                            required={!optional}
                        />
                    </>
                )}
            </Field>
        </FieldLayout>
    )
}

export default NumericInputField;