import React, {useEffect, useContext, useState} from 'react';
import PropTypes from 'prop-types';
import DynamicForm from "../../DynamicForm";
import Recycle from "../../../Recycle";
import {useHistory} from "react-router-dom";
import {AppConfigContext} from "../../../App";
import GoodsReceivingItems from "./GoodsReceivingItems"
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {Button} from "@material-ui/core";
import {useSnackbar} from "notistack";
import Paper from "@material-ui/core/Paper";

const ReceiveSupplierStock = props => {
    const history = useHistory();
    const classes = useStyles();
    const appConfig = useContext(AppConfigContext);
    const {enqueueSnackbar} = useSnackbar();

    const [supplierName, setSupplierName] = useState(null);
    const [invoiceNumber, setInvoiceNumber] = useState(null);
    const [invoiceDocument, setInvoiceDocument] = useState(null);

    const handleReceiveSupplierStock = (values) => {
        setSupplierName(values.supplierName);
        setInvoiceNumber(values.invoiceNumber);
        setInvoiceDocument(values.invoice);
    }

    let site_name = appConfig.user?.dsp.replace(/\//g, "_");

    let receive_supplier_stock = {
        "id": "RECEIVE_SUPPLIER_STOCK",
        "name": "Receive Supplier Stock",
        "groups": [
            {
                "id": "part_1",
                "fields": [
                    {
                        "id": "supplierName",
                        "name": "Supplier Name",
                        "type": "text",
                        "default": "None",
                        "optional": false,
                        "display_view": "text"
                    },
                    {
                        "id": "invoiceNumber",
                        "name": "Invoice Number",
                        "type": "text",
                        "default": "None",
                        "optional": false,
                        "display_view": "text"
                    },
                    {
                        "id": "invoice",
                        "name": "Upload Invoice Document",
                        "type": "universal_upload",
                        "default": "None",
                        "optional": true,
                        "metadata": {
                            "key": `stock_management/invoices/${site_name}`
                        }
                    }
                ]
            }
        ]
    }

    const renderReceiveSupplierStock = () => (
        <DynamicForm onSubmit={handleReceiveSupplierStock}
                     config={{fields: receive_supplier_stock, defaultValues: {}}}
                     actions={[
                         ({submitting}) => (<Button
                             key="next"
                             variant="contained"
                             color="primary"
                             type="submit"
                             disabled={submitting}
                         >
                             Next
                         </Button>)
                     ]}
        />)

    const supplierDetailsView = () => {
        return (
            <Paper className={classes.rootContainer}>
                <Typography className={classes.helperText}>
                    Receive Supplier Stock
                </Typography>
                {renderReceiveSupplierStock()}
            </Paper>
        );
    }

    return (
        <>{supplierName && invoiceNumber ? <GoodsReceivingItems
            getStockItems={props.getStockItems}
            getGRVStockMoves={props.getGRVStockMoves}
            saveStockReceived={props.saveStockReceived}
            supplierName={supplierName}
            invoiceNumber={invoiceNumber}
            invoiceDocument={invoiceDocument}
        /> : supplierDetailsView()}</>
    );
};

export default ReceiveSupplierStock;

const useStyles = makeStyles(theme => ({
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(2)
    },
    updateButton: {
        marginRight: theme.spacing(2)
    },
    title: {
        marginLeft: theme.spacing(1),
    },
    rootContainer: {
        padding: "40px 20px 80px 20px"
    },
    helperText: {
        fontSize: '34px',
        paddingBottom: "40px",
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(2)
    },
}));