import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import ConsultCompleteDocumentTable from './ConsultCompleteDocumentTable'
import Button from "@material-ui/core/Button";

const PatientHistoryTableDialog = (props) => {
    const data = {
        consult: {
            consult_id: props.consult?.consult_id
        },
        type: "history_table"
    }
    return (
        <div>
            <div>
                <Dialog maxWidth="md" fullWidth open={props.open}
                        aria-labelledby="form-dialog-title">
                    <DialogContent>
                        <ConsultCompleteDocumentTable config={{title: props.consult?.tariff_description}} props={data}/>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={props.handleCloseConsultDocumentTable} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

        </div>
    );
};

export default PatientHistoryTableDialog;
