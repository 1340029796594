import React, {useRef, useState} from 'react';
import {Field} from 'react-final-form';
import {DatePicker} from '@material-ui/pickers';
import FieldLayout from "./FieldLayout";

const DateField = ({id, name, optional, initial, description, warning}) => {
    let label = name;
    return (
        <>
            <FieldLayout description={description} warning={warning}>
                <Field name={id}
                       placeholder={label}
                       optional={optional}
                       initial={initial}
                       component={DateFieldAdapter}>

                </Field>
            </FieldLayout>

        </>
    )
}

const DateFieldAdapter = ({input, ...rest}) => {
    const {onChange, ...restInput} = input;
    const [value, setValue] = useState(null)


    React.useEffect(() => {
        onChange(value === '' ? null : value)
    }, [value])

    return (<>
        <DatePicker
            style={{width: "100%"}}
            value={value}
            name={rest.name}
            label={rest.placeholder}
            onChange={(date)=>setValue(date)}
            required={rest.optional === false}
            format="DD/MM/YYYY"
            // openTo={"year"}
        />
        <input
            tabIndex={-1}
            autoComplete="off"
            style={{opacity: 0, height: 0}}
            value={value}
            required={true}
            // readOnly
            onChange={() => {
            }}
        />
    </>)
}

export default DateField;