import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import dayjs from 'dayjs';
import {Button} from "@material-ui/core";

const ItemNameStatusCell = ({stockItem}) => {
    const classes = useStyles();
    // console.log("stockItem", stockItem)
    const batchItemStatusRender = (stockItem) => {
        if (!stockItem.item.is_batch_item)
        {
            return null;
        }
        return (stockItem.item.batch_stock_moves_captured) ? null : <div className={classes.statusRectangle}></div>
    };

    return (
        <div className={classes.root}>
            {batchItemStatusRender(stockItem)}
            <div>
                {stockItem.item.name}
            </div>
        </div>
    )
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center'
    },
    statusRectangle: (props) => {
        const backgroundColor = theme.palette.warning.main;

        return {
            width: '6px',
            height: '30px',
            position: 'absolute',
            left: 0,
            backgroundColor: backgroundColor
        }
    },
    status: (props) => {
        const color = theme.palette.warning.main;

        return {
            fontSize: '14px',
            fontWeight: 600,
            color: color,
            textTransform: 'capitalize'
        }

    },
    arrival: {
        fontSize: '11px',
        color: '#666666',
        lineHeight: '15px'
    }
}));

export default ItemNameStatusCell;