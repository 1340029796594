import React, {useEffect, useState, useContext, useRef} from "react";
import MaterialTable, {MTableAction} from '@material-table/core';
import Button from "@material-ui/core/Button";
import Recycle from "../../../Recycle";
import {AppConfigContext, SharedStateContext} from "../../../App";

import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";
import {columnStyle} from "../StockTable";

import {Fab, MenuItem, TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";

import CreateIcon from "@material-ui/icons/Create";
import DispensingBatchDetailsDialog from "../DispensingBatchDetailsDialog";
import {isEmpty} from "../utils/isEmpty";
import BatchDetailsDialog from "../BatchDetailsDialog";

const MedicationAndConsumablesTable = ({config, props}) => {
    const sharedState = useContext(SharedStateContext);
    const [openBatchDetailsDialog, setOpenBatchDetailsDialog] = useState(false);
    const [currentStockBatchDetails, setCurrentStockBatchDetails] = useState({});
    const [stockItems, setStockItems] = useState([]);
    const [medicationListExpected, setMedicationListExpected] = useState([]);
    const batchDialogRef = useRef();
    const appConfig = useContext(AppConfigContext);

    useEffect(() => {
        if(props.dsp_details.is_stock_module_site) {
            getTariffMedications();
            getStockItems();
        }
    }, []);

    const batchItemButtonRender = (stockItem) => {
        if (!stockItem.is_batch_item) {
            return null;
        }
        return (stockItem.batch_stock_moves_captured) ?
            <Button onClick={() => editDetails(stockItem)} variant="outlined">EDIT DETAILS</Button> :
            <Button onClick={() => addDetails(stockItem)} variant="outlined">ADD DETAILS</Button>
    };

    const addDetails = (stockItem) => {
        setCurrentStockBatchDetails(stockItem)
        setOpenBatchDetailsDialog(true)
    }

    const editDetails = (stockItem) => {
        console.log(stockItem)
        console.log("stockItem")
        setCurrentStockBatchDetails(stockItem)
        batchDialogRef.current.startEditExistingDetails();
        setOpenBatchDetailsDialog(true)

    }
    const columns = [
        {
            field: "name",
            title: "ITEM",
            editable: 'onAdd',
            // validate: rowData => rowData.name === undefined ? false : rowData.name === '' ? false : true,
            editComponent: props => {
                return <DropDown
                    value={props.value}
                    onChange={(e, value) => {
                        props.onChange(value.stock_item_id);
                    }}
                />
            },
        },
        {
            field: "quantity",
            title: "QUANTITY",
            type: 'numeric',
            validate: rowData => rowData.quantity >= 0,
            editComponent: props => {
                return <TextField value={props.value || ""} onChange={e => props.onChange(e.target.value)} label="Quantity" type="number" />
            }
        },
        {
            field: 'detailsAdded',
            title: '',
            editable: 'never',
            render: (rowData) => (rowData.is_batch_item && rowData.batch_stock_moves_captured) ?
                <Chip
                    variant={"outlined"}
                    color='default' style={{color: 'green'}}
                    icon={<DoneIcon/>}
                    label="DETAILS ADDED"/> : "",
            ...columnStyle("15%", "left"),
        },
        {
            field: "batchExists",
            title: "",
            editable: 'never',
            render: (rowData) => batchItemButtonRender(rowData)
        }
    ];

    const DropDown = ({value, onChange}) => {
        const medications = sharedState.medications || [];
        let tmpMap = {}
        medications.forEach((item) => tmpMap[item.stock_item_id] = true)
        const dropDownOptions = stockItems.filter((item) => !(item.stock_item_id in tmpMap))
        return (
            <Autocomplete
                id="medicationName"
                options={dropDownOptions}
                onChange={onChange}
                getOptionLabel={(option) => option.name}
                style={{width: 300}}
                renderInput={(params) => <TextField {...params} label="Medication Item"/>}
            />
        )
    };

    const getStockItems = () => {
        Recycle.rpc({
            model: "StockManagement",
            method: "get_stock_moves_for_site",
            args: [props.site_id, false, 'dispensed']
        }).then((res) => {
            setStockItems(res)
        }).catch((err) => console.log(err))
    }


    const getTariffMedications = () => {
        Recycle.rpc({
            model: "StockManagement",
            method: "get_medication_for_consult_for_site",
            args: [props.tariff_code, props.site_id]
        }).then((res) => {
            const medications = sharedState.medications || [];
            const medicationsForTariff = res.medication_list_for_tariff;
            setMedicationListExpected([...res.medication_list_for_tariff]);
            const newMedications = [...medicationsForTariff];
            setMedications(newMedications);
        });
    }

    const setMedications = (medications) => {
        const checkMedicationIsInvalid = (item) => {
            return (item.is_batch_item === true && item.batch_stock_moves_captured !== true)
        }

        const medicationIsValid = !medications.some(checkMedicationIsInvalid)
        if (medicationIsValid) {
            const expected_count = medicationListExpected.reduce((accumulator, item) => item.quantity + accumulator, 0);
            const dispensed_count = medications.reduce((accumulator, item) => item.quantity + accumulator, 0);
            const deviation = dispensed_count - expected_count
            const payload = {
                consult_id: props.consult_id,
                site_id: props.site_id,
                tariff_code: props.consult.tariff_code,
                tariff_description: props.consult.tariff_description,
                site_name: appConfig.dsp_details.name,
                deviation: deviation,
                medication_list_dispensed: medications,
                medication_list_expected: medicationListExpected,

            }
            console.log(payload)
            Recycle.rpc({
                model: "StockManagement",
                method: "save_dispensed_consult_medication",
                args: [props.site_id, payload]
            }).then((res) => {
                console.log(res)
            }).catch((err) => console.log(err));

        }
        sharedState.setState({...sharedState, medications});
    }

    const updateBatchDetails = (batchInformation, stockId, quantitySum, originalGetBatchExpMap) => {
        const medications = sharedState.medications;
        console.log(originalGetBatchExpMap)
        const index = medications.findIndex(obj => obj.stock_item_id === stockId);
        if (index !== -1) {
            medications[index].batch_items = batchInformation;
            medications[index].batch_stock_moves_captured = true;
            medications[index].quantity = quantitySum;
            medications[index]['original_get_batch_exp_map'] = originalGetBatchExpMap;
            setMedications(medications);
        }
    }

    if (props.dsp_details.is_stock_module_site) {
        return (
            <React.Fragment>
                <MaterialTable
                    data={sharedState.medications || []}
                    columns={columns}
                    title="Medication and Consumables"
                    components={{
                        Action: (props) => {
                            if (typeof props.action === typeof Function || props.action.tooltip !== "Add") {
                                return <MTableAction {...props} />;
                            } else {
                                return <Fab color={"primary"} aria-label="createMeds" onClick={props.action.onClick}>
                                    <CreateIcon/>
                                </Fab>;
                            }
                        }
                    }}
                    options={{
                        headerStyle: {
                            color: '#999999',
                            fontSize: '11px',
                            fontWeight: 600,
                            lineHeight: '15px'
                        },
                        search: false,
                        actionsColumnIndex: -1,
                    }}
                    actions={[]}

                    editable={{
                        onRowAdd: newData =>

                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    const medication = sharedState.medications || [];
                                    let newRow = stockItems.find(element => element.stock_item_id === newData.name)
                                    newRow.quantity = newData.quantity
                                    setMedications([...medication, newRow]);
                                    resolve();
                                }, 10)
                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    if (oldData.is_batch_item) {
                                        resolve();
                                    } else {
                                        const dataUpdate = sharedState.medications;
                                        const index = oldData.tableData.id;
                                        dataUpdate[index] = newData;
                                        setMedications([...dataUpdate]);
                                        resolve();
                                    }
                                }, 10)
                            }),
                        onRowDelete: oldData =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    const dataDelete = sharedState.medications;
                                    const index = oldData.tableData.id;
                                    dataDelete.splice(index, 1);
                                    setMedications([...dataDelete]);
                                    resolve();
                                }, 20)
                            }),
                    }}
                />
                <BatchDetailsDialog updateBatchDetails={updateBatchDetails} allowBatchEdition={false}
                                    stockItem={currentStockBatchDetails}
                                    open={openBatchDetailsDialog}
                                    item_identifier={'stock_item_id'}
                                    type={'dispensing'}
                                    ref={batchDialogRef}
                                    setClose={() => setOpenBatchDetailsDialog(false)}/>
            </React.Fragment>
        )
    }
    else {
        return null;
    }
}

export default MedicationAndConsumablesTable;