import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core';
import { sharedStyles } from '../shared/styles';
import Button from '@material-ui/core/Button';
import Recycle from '../Recycle';
import {useSnackbar} from "notistack";

const Signoff = ({ config, props: {consult_id, consult_status, dsp_details} }) => {
    const sharedClasses = sharedStyles();
    const classes = useStyles();
    const history = useHistory();
    const [notes, setNotes] = useState("");
    const [submitting, setSubmitting] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        if (consult_status && consult_status === 'completed') {
            history.push(`/consults/${consult_id}/signoffcomplete`);
        }
    }, [consult_id, consult_status, history])

    const signoff = () => {
        setSubmitting(true);

        if (dsp_details.is_agenda_site ) {
            Recycle.rpc({
                model: "AgendaManager",
                method: "update_or_create_agenda",
                args: [consult_id]
            }).then(() => {});
        }

        Recycle.rpc({ model: "ConsultController", method: "signoff", args: [consult_id, notes] }).then((res) => {
            setSubmitting(false);
            history.push(`/consults/${consult_id}/signoffcomplete`);
            enqueueSnackbar("OMP Sign-off Complete", { variant: "success" });

        }).catch(err => {
            setSubmitting(false);
        })
    }

    return (
        <Paper className={classes.container}>
            <div className={sharedClasses.heading}>Sign Off Approval</div>
            <TextField
                autoFocus
                onChange={(e) => setNotes(e.target.value)}
                label="Notes"
                multiline
                fullWidth
                value={notes}
            />
            <Button className={classes.button} variant="contained" color="primary" onClick={signoff} disabled={submitting}>confirm</Button>
        </Paper>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: "grid",
        gridTemplateColumns: "1fr",
        gap: `${theme.spacing(2)}px`,
        padding: theme.spacing(3)
    },
    button: {
        justifySelf: "end"
    }
}));

export default Signoff;