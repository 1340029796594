import React, {useState, useContext} from 'react';
import {useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {useSnackbar} from 'notistack';
import Recycle from '../Recycle';
import {SharedStateContext} from "../App";
import Box from '@material-ui/core/Box';

const SignoffBanner = ({config, props}) => {
    const classes = useStyles();
    const history = useHistory();
    const sharedState = useContext(SharedStateContext);
    const [submitting, setSubmitting] = useState(false);
    const {enqueueSnackbar} = useSnackbar();


    const requestSignoff = () => {
        if (props.dsp_details.is_agenda_site) {
            history.push(`/chronic-conditions/${props.consult.consult_id}`);
        }
        else {
            setSubmitting(true);
            Recycle.rpc({
                model: "ConsultController",
                method: "request_signoff",
                args: [props.consult.consult_id]
            }).then((res) => {
                setSubmitting(false);
                enqueueSnackbar("Sign off requested", { variant: "success" });
                history.push('/dashboard');
            }).catch(err => {
                setSubmitting(false);
                enqueueSnackbar("Failed to request sign off", { variant: "error" });
            });
        }
    }

    const consultComplete = () => {
        if (props.dsp_details.is_agenda_site) {
            history.push(`/chronic-conditions/${props.consult.consult_id}`);
            return;
        }
        setSubmitting(true);
        Recycle.rpc({
            model: "ConsultController",
            method: "early_consult_complete",
            args: [props.consult.consult_id]
        }).then((res) => {
            setSubmitting(false);
            enqueueSnackbar("Consult completed", {variant: "success"});
            history.push(`/consults/${props.consult.consult_id}/signoffcomplete`);
        }).catch(err => {
            setSubmitting(false);
            enqueueSnackbar("Failed to complete consult", {variant: "error"});
        });
    }

    const proceedToSignoff = () => {
        history.push(`/consults/${props.consult.consult_id}/signoff`);
    }

    const proceedToDashboard = () => {
        history.push('/dashboard');
    }

    const renderAction = () => {
        if (props.consult.consult_status === 'procedures_completed') {
            if ("signoff_required" in props.consult && props.consult.signoff_required === false) {
                return (
                    <Button variant="contained" onClick={consultComplete} disabled={submitting}>
                        close case
                    </Button>
                )
            }
            else {
                return (
                    <Button variant="contained" onClick={requestSignoff} disabled={submitting}>
                        request sign off
                    </Button>
                )
            }
        } else if (props.consult.consult_status === 'awaiting_omp') {
            return <Button variant="contained" onClick={proceedToSignoff} disabled={submitting}>proceed to
                signoff</Button>
        } else if (props.consult.consult_status === 'completed') {
            return <Button variant="contained" onClick={proceedToDashboard} disabled={submitting}>Back To
                Dashboard</Button>
        }
    }

    const medicationListValid = () => {
        const medications = sharedState.medications || [];
        const checkMedicationIsInvalid = (item) => {
            return (item.is_batch_item === true && item.batch_stock_moves_captured !== true)
        }

        return !medications.some(checkMedicationIsInvalid)
    }

    const renderBanner = () => {
        if (props.dsp_details.is_stock_module_site) {
            if (!medicationListValid()) {
                return <div className={classes.containerYellow}>
                    <div className={classes.text}>Please Capture the Batch Details for Medication and Consumables
                    </div>
                </div>
            } else {
                return renderConsultBasedBanner()

            }

        } else {
            return renderConsultBasedBanner()
        }
    }

    const renderConsultBasedBanner = () => {
        return (<>
            {
                ["procedures_completed", "awaiting_omp"].indexOf(props.consult.consult_status) > -1 ?
                    <div className={classes.container}>
                        <div className={classes.text}>All Medical Analysis Completed</div>
                        {renderAction()}
                    </div> :
                    null
            }
            {
                ["completed"].indexOf(props.consult.consult_status) > -1 ?
                    <div className={classes.container}>
                        <div className={classes.text}>Sign Off Successful</div>
                        {renderAction()}
                    </div> :
                    null
            }
        </>)
    }

    return (
        <React.Fragment>
            {renderBanner()}
        </React.Fragment>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.success.main,
        borderRadius: '3px',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(3)
    },
    containerYellow: {
        backgroundColor: theme.palette.warning.main,
        borderRadius: '3px',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(3)
    },
    text: {
        fontSize: '14px',
        fontWeight: 'bold'
    }
}));

export default SignoffBanner;