import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import ReactMarkdown from 'react-markdown'
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import {useHistory} from "react-router-dom";

const ConsultInfoPanel = ({config, props}) => {
    const classes = useStyles();
    const history = useHistory();

    const handleRouting = () => {
        history.push(`/consults/${props?.info_panel?.consult_id}`)
    }

    let info_panel =
        <>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                className={classes.back_group}
            >
                <Button onClick={handleRouting} className={classes.back_button_style} style={{textTransform: 'none'}}>
                    <ArrowBackIosIcon style={{fontSize: '15px'}}/>
                    <div className={classes.back_text}>Patient Consult</div>
                </Button>
            </Grid>

            <Paper style={{minHeight: '65vh'}} className={classes.root}>
                <p className={classes.heading}> Instruction Notes </p>
                <Divider className={classes.divider} variant="fullWidth"/>
                <ReactMarkdown skipHtml>
                    {props?.info_panel?.info_panel ? props.info_panel.info_panel : null}
                </ReactMarkdown>
            </Paper>
            <Grid container
                  direction="row"
                  justify="flex-end"
                  alignItems="flex-end"
                  style={{marginTop: 16, padding: "15px", gridGap: 12}}>
                <Button
                    onClick={handleRouting}
                    variant="contained"
                    color="primary"
                    type="submit"
                >
                    Done
                </Button>
            </Grid>
        </>
    return info_panel
}

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 20,
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    heading: {
        marginTop: 0,
        fontSize: "1.25rem",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        fontWeight: 500,
        lineHeight: 1.6,
        letterSpacing: "0.0075em",
    },
    back_text: {
        fontSize: "1rem",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        fontWeight: 500,
        lineHeight: 1.6,
        letterSpacing: "0.0075em",
    },
    back_group: {
        paddingTop: 0,
        paddingBottom: '15px',
    },
    back_button_style: {
        '& > *': {
            margin: theme.spacing(1),
        },
        paddingTop: 0,
        paddingBottom: 0,
    },
}));

export default ConsultInfoPanel;