import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

export default function StockTransferIdDialog({stockTransferId, open, handleClose, onDone}) {
    const classes = useStyles();

    return (
        <div>
            <Dialog
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogTitle style={{paddingLeft:'0px'}} id="alert-dialog-title">{"Stock Transfer Complete"}</DialogTitle>
                    <DialogContentText id="alert-dialog-description">
                        Stock Transferred out successfully. Stock Transfer Reference
                        Number: <br/> <strong>{stockTransferId}</strong>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Grid container direction="row" justify="flex-end" alignItems="center">
                        <Button
                            key="next"
                            variant="contained"
                            color="primary"
                            onClick={onDone}
                            className={classes.doneButton}
                        >
                            OK
                        </Button>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    actions: {
        padding: 0
    },
    s: {
        marginRight: "24px"
    },
    doubleCheckButton: {
        width: "300px",
        fontSize: '15px',
        color: "grey",
    },
    doneButton: {
        width: "100px",
        height: "7%",
        // fontSize: '30px',
        justifyContent: 'center',
        alignSelf: 'center',
    },
    subheading: {
        fontSize: '26px',
        marginBottom: theme.spacing(2)
    },
}));
