import React, {useRef, useState} from "react";
import MaterialTable, {MTableHeader} from "@material-table/core";
import TableBody from "./TableBody";
import {useDimensions} from "./useDimensions";
import {Button, Paper} from "@material-ui/core";
import ItemNameStatusCell from "./ItemNameStatusCell";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import BatchDetailsDialog from "../BatchDetailsDialog";
import Chip from "@material-ui/core/Chip";
import DoneIcon from '@material-ui/icons/Done';

export const columnStyle = (width, align, firstCol = true) => ({
    width: width,
    maxWidth: width,
    cellStyle: {
        width: width,
        maxWidth: width,
        textAlign: align,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        paddingLeft: firstCol ? "1rem" : "auto"
    },
    headerStyle: {
        width: width,
        maxWidth: width,
        textAlign: align,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        paddingLeft: firstCol ? "1rem" : "auto"
    },
    filterCellStyle: {
        width: width,
        maxWidth: width,
        textAlign: align,
        position: "sticky",
        top: "41px", // header height
        backgroundColor: "white"
    }
});

const StockTable = (props) => {
    const [
        tableRef,
        {width: tableWidth, height: tableHeight}
    ] = useDimensions();
    const tableDataRef = React.createRef();
    const [openBatchDetailsDialog, setOpenBatchDetailsDialog] = React.useState(false);
    const [currentStockBatchDetails, setCurrentStockBatchDetails] = React.useState({});
    const [tableHeaderRef, {height: tableHeaderHeight}] = useDimensions();
    const [scrollIndex, setScrollIndex] = useState();
    const [tableData, setTableData] = useState();
    const batchDialogRef = useRef();

    let testIndex = 100;
    const handleScrollToIndex = () => {
        setScrollIndex(testIndex); //FIXME doesn't work, index rows are not kept after sorting/filtering
    };

    React.useEffect(() => {
        if (!tableDataRef.current || !props.stockItems)
            return;

        setTableData(props.stockItems);

        // NB: page, orderBy and orderDirection intentionally left out of dep array
    }, [props.stockItems, tableDataRef])

    const handleOpenBatchDetailsDialog = (details) => {
        setCurrentStockBatchDetails(details)
        setOpenBatchDetailsDialog(true);
    };

    const handleEditBatchDetailsDialog = (details) => {
        setCurrentStockBatchDetails(details)
        batchDialogRef.current.startEditExistingDetails();
        setOpenBatchDetailsDialog(true);
    };

    const batchItemButtonRender = (rowData) => {
        if (!rowData.item.is_batch_item) {
            return null;
        }
        const stock_item = rowData.item;
        return (rowData.item.batch_stock_moves_captured) ?
            <Button onClick={() => handleEditBatchDetailsDialog(stock_item)} variant="outlined">EDIT DETAILS</Button> :
            <Button onClick={() => handleOpenBatchDetailsDialog(stock_item)} variant="outlined">ADD DETAILS</Button>
    };

    const updateBatchDetails = (batchInformation, stockId, quantitySum, originalGetBatchExpMap = {}) => {
        props.updateBatchStock(batchInformation, stockId, quantitySum, originalGetBatchExpMap);
    }

    const tableColumns = [
        {
            field: 'name',
            title: 'ITEM',
            customSort: (a, b) => a.item.name > b.item.name ? 1 : -1,
            render: (rowData) => <ItemNameStatusCell stockItem={rowData}/>,
            ...columnStyle("40%", "left"),
        },
        {
            field: 'unitsReceived',
            title: 'Quantity',
            customSort: (a, b) => a.item.quantity > b.item.quantity ? 1 : -1,
            render: (rowData) => <p>{rowData.item.quantity}</p>,
            ...columnStyle("15%", "left"),
        },
        {
            field: 'detailsAdded',
            title: '',
            customSort: (a, b) => a.item.is_batch_item > b.item.is_batch_item ? -1 : 1,
            render: (rowData) => (rowData.item.is_batch_item && rowData.item.batch_stock_moves_captured) ?
                <Chip
                    variant={"outlined"}
                    color='default' style={{color: 'green'}}
                    icon={<DoneIcon/>}
                    label="DETAILS ADDED"/> : "",
            ...columnStyle("25%", "left"),
        },
        {
            field: 'batchExists',
            title: '',
            customSort: (a, b) => a.item.is_batch_item > b.item.is_batch_item ? -1 : 1,
            defaultSort: 'asc',
            render: (rowData) => batchItemButtonRender(rowData),
            ...columnStyle("25%", "center"),
        },
        // {field: 'status', title: 'Status', render: (rowData) => <StatusCell status={rowData.status}/>},
        {
            field: 'actions',
            title: '',
            render: (rowData) => <IconButton
                onClick={() => props.removeReceivedItem(rowData.item[props.item_identifier])}
                edge="end"
                aria-label="delete"><DeleteIcon/></IconButton>,
            ...columnStyle("10%", "center"),
        }
    ];

    return (
        <>
            <BatchDetailsDialog updateBatchDetails={updateBatchDetails} allowBatchEdition={props.allowBatchEdition}
                                stockItem={currentStockBatchDetails}
                                open={openBatchDetailsDialog}
                                item_identifier={props.item_identifier}
                                type={props.type}
                                ref={batchDialogRef}
                                setClose={() => setOpenBatchDetailsDialog(false)}/>
            <div ref={tableRef}>
                <MaterialTable
                    title={props.stockItems.length === 1 ? `${props.stockItems.length} Item added` : `${props.stockItems.length} Items added`}
                    tableRef={tableDataRef}
                    columns={tableColumns}
                    data={tableData}
                    options={{
                        paging: false,
                        filtering: false,
                        search: false,
                        searchFieldAlignment: "left",
                        minBodyHeight: "48vh", //FIXME to calculate dynamic height, needed for correct scroll position identification
                        maxBodyHeight: "48vh"
                    }}
                    components={{
                        Body: (props) => (
                            <TableBody
                                {...props}
                                headerHeight={tableHeaderHeight}
                                tableWidth={tableWidth}
                                tableHeight={tableHeight}
                                scrollIndex={scrollIndex}
                            />
                        ),
                        Container: props => <Paper {...props} elevation={0}/>,
                        Header: (props) => (
                            // <div ref={tableHeaderRef} className="table-header-row">
                            <MTableHeader ref={tableHeaderRef} className="table-header-row" {...props} />
                            // </div>
                        )
                    }}
                />
            </div>
        </>
    );
};

export default StockTable;
