import React, {useEffect, useContext, useState} from 'react';
import PropTypes from 'prop-types';
import DynamicForm from "../../DynamicForm";
import Recycle from "../../../Recycle";
import {useHistory} from "react-router-dom";
import {AppConfigContext} from "../../../App";
import TransferStockInFromAnotherClinicItems from "./TransferStockInFromAnotherClinicItems"
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {useSnackbar} from "notistack";
import Paper from "@material-ui/core/Paper";
import {GetClinicsTransferIn} from "../../../StockManagerConfig";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import {InputLabel, MenuItem, Select, TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";

const TransferStockInFromAnotherClinic = props => {
    const history = useHistory();
    const classes = useStyles();
    const appConfig = useContext(AppConfigContext);
    const {enqueueSnackbar} = useSnackbar();
    const [clinicName, setClinicName] = useState(null);
    const [pageSelected, setPageSelected] = useState(0);
    const [selectedSiteOptions, setSelectedSiteOptions] = useState([]);
    const [selectedSite, setSelectedSite] = useState(null);
    const [selectedTransferIDOptions, setSelectedTransferIDOptions] = useState([]);
    const [selectedTransferIDMap, setSelectedTransferIDMap] = useState({});

    const [transferNumber, setTransferNumber] = useState(null);

    function getAvailableStockTransfers() {
        const site = appConfig.user?.dsp;
        props.GetClinicsTransferIn(site).then((result) => {
            const setSiteOptions = (result.site_array.map(item => {
                return {id: item.id, name: item.name}
            }))
            setSelectedSiteOptions(setSiteOptions)
            setClinicName(setSiteOptions)
            setSelectedTransferIDMap(result.site_transfer_out_id_map)

        }).catch(err => console.log(err));

    }

    React.useEffect(() => {
        getAvailableStockTransfers();
    }, [])


    const handleReceiveSupplierStock = (values) => {
        setClinicName(values.clinicName);
        setTransferNumber(values.transferNumber);
    }

    const renderInboundStock = () => (
        <div>
            <div style={{display: 'grid', gridRowGap: '10px'}}>
                <Autocomplete
                    disableClearable={true}
                    id="combo-box-clinic-name"
                    options={selectedSiteOptions}
                    getOptionLabel={(option) => option.name}
                    onChange={(e, value) => {
                        setClinicName(value.id)
                        setSelectedTransferIDOptions(selectedTransferIDMap[value.id])

                    }}
                    style={{width: 300}}
                    renderInput={(params) => <TextField {...params} label="Clinic Name"/>}
                />
                <Autocomplete
                    disableClearable={true}
                    id="combo-box-tn"
                    options={selectedTransferIDOptions}
                    onChange={(e, value) => {
                        setTransferNumber(value)
                    }}
                    style={{width: 300}}
                    renderInput={(params) => <TextField {...params} label="Transfer Number"/>}
                /></div>

            <div style={{marginTop: '15px', display: 'grid', gridTemplateColumns: 'auto auto', direction: 'rtl'}}>
                <Button style={{width: '150px'}} onClick={() => setPageSelected(1)}
                        variant={"contained"}
                        disabled={!(transferNumber && clinicName)}
                        color={"primary"}> Next</Button>
            </div>
        </div>)

    const supplierDetailsView = () => {
        return (
            <Paper className={classes.rootContainer}>
                <Typography className={classes.helperText}>
                    Receive stock from another clinic
                </Typography>
                {renderInboundStock()}
            </Paper>
        );
    }

    return (
        <>{clinicName && transferNumber && pageSelected ===1 ? <TransferStockInFromAnotherClinicItems
            getTransferStockItems={props.getTransferStockItems}
            saveStockReceivedTransferIn={props.saveStockReceivedTransferIn}
            clinicName={clinicName}
            transferNumber={transferNumber}
        /> : supplierDetailsView()}</>
    );
};

export default TransferStockInFromAnotherClinic;

const useStyles = makeStyles(theme => ({
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(2)
    },
    updateButton: {
        marginRight: theme.spacing(2)
    },
    title: {
        marginLeft: theme.spacing(1),
    },
    rootContainer: {
        padding: "40px 20px 80px 20px"
    },
    helperText: {
        fontSize: '34px',
        paddingBottom: "40px",
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(2)
    },
}));

