import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import dayjs from "dayjs";
import InfoText from '../InfoText';


const AppointmentDetails = ({ config, props }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <img className={classes.profileImage} src="/logo.png" alt="profile" />

            <div>
                <div className={classes.fullName}>{props.booking.patient.name}</div>
                <div className={classes.scheduleContainer}>
                    <InfoText
                        className={classes.dayAndTimeContainer}
                        placeholder="Appointment Scheduled On:"
                        text={dayjs(props.booking.scheduled_at).format('dddd, DD/MM/YYYY')}
                    />
                    <InfoText
                        placeholder="Arrival Time:"
                        text={dayjs(props.booking.scheduled_at).format('HH:mmA')}
                    />
                </div>
            </div>
        </div>
    )
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    profileImage: {
        height: '82px',
        width: '82px',
        marginRight: theme.spacing(2)
    },
    fullName: {
        fontSize: '34px',
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(2)
    },
    scheduleContainer: {
        display: 'flex',
        fontSize: '12px'
    },
    dayAndTimeContainer: {
        marginRight: theme.spacing(5)
    }
}));

export default AppointmentDetails;