import React, {useContext, useEffect} from 'react';
import MaterialTable from '@material-table/core';
import {makeStyles} from '@material-ui/core/styles';
import ReferralNote from "../ReferralNote";
import useInterval from "../../hooks/useInterval"
import Chip from "@material-ui/core/Chip";
import Recycle from "../../Recycle";
import {AppConfigContext} from "../../App";
import DocumentViewerDialog from "../DocumentViewer/DocumentViewerDialog";
import PageviewIcon from '@material-ui/icons/Pageview';
import CreatedByOrDateCell from "./CreatedByOrDateCell";
import {Button} from "@material-ui/core";

const ConsultCompleteDocumentTable = ({config, props}) => {
    const classes = useStyles();
    const [tableData, setTableData] = React.useState([]);
    // const [tableLoading, setTableLoading] = React.useState(false);
    const [isTimerRunning, setIsTimerRunning] = React.useState(true)
    const appConfig = useContext(AppConfigContext);
    const [openPdfViewer, setOpenPdfViewer] = React.useState(false);
    const [pdfB64String, setPdfB64String] = React.useState('');
    const [documentTitle, setDocumentTitle] = React.useState('');

    useEffect(() => {
        Recycle.rpc({
            model: "ConsultController",
            method: "get_documents_for_consult",
            args: [props.consult.consult_id]
        }).then((res) => {
            setTableData(res)
        })

    }, [])

    // useInterval(() => {
    //     // Your custom logic here
    //     // setTableLoading(true)
    //     Recycle.rpc({
    //         model: "ConsultController",
    //         method: "get_documents_for_consult",
    //         args: [props.consult.consult_id]
    //     }).then((res) => {
    //         setTableData(res)
    //         if (res.every((v) => v.document_status === 'generated') === true) {
    //             setIsTimerRunning(false)
    //
    //         } else {
    //             setIsTimerRunning(true)
    //
    //         }
    //     }).catch((err) => {
    //
    //     })
    // }, isTimerRunning ? 1000 : null);

    const handleOpenPDF = () => {
        setOpenPdfViewer(true)

    };

    const setClosePdfViewer = () => {
        setOpenPdfViewer(false)

    }


    const handleOpenDocument = (rowData) => {
        Recycle.rpc({
            model: "DocumentController",
            method: "get_document_as_base64",
            args: [props.consult_id, rowData.document_id]
        }).then((res) => {
            setPdfB64String(res.document_b64)
            setDocumentTitle(rowData.document_title)
            handleOpenPDF()
        }).catch((err) => {

        })
    }

    const columns = [
        {
            field: 'document_title',
            title: 'TITLE',
        },
        {
            field: (props.type && props.type === "history_table") ? 'created_at' : 'created_by',
            title: (props.type && props.type === "history_table") ? 'CREATED ON' : 'CREATED BY',
            render: (rowData) => <CreatedByOrDateCell type={props.type} history={rowData}/>
        },
        {
            field: 'document_type',
            title: 'DOCUMENT TYPE',
        },

    ]
    return (
        <><MaterialTable
            data={tableData}
            columns={columns}
            title={config.title ? config.title : "Documents"}
            // isLoading={tableLoading}
            actions={[
                rowData => ({
                    icon: () => <Button color="primary" variant="contained"
                                        style={{marginRight: 15}}> View </Button>, tooltip: 'View document',
                    onClick: (event, rowData) => handleOpenDocument(rowData),
                    disabled: rowData.document_status !== 'generated'
                })

            ]}

            options={{
                headerStyle: {
                    color: '#999999',
                    fontSize: '11px',
                    fontWeight: 600,
                    lineHeight: '15px'
                },
                search: false,
                actionsColumnIndex: -1

            }}
        />
            <DocumentViewerDialog title={documentTitle} open={openPdfViewer} data={pdfB64String}
                                  setClose={setClosePdfViewer}/>

        </>

    )
};

const useStyles = makeStyles((theme) => ({
    actionsContainer: {
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        columnGap: theme.spacing(1),
        width: '224px',
        marginLeft: 'auto',
        marginRight: 'auto'
    }
}));

export default ConsultCompleteDocumentTable;