import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InfoText from '../InfoText';

const BookingDetails = ({ config, props }) => {
    const classes = useStyles();

    return (
        <div>
            <div className={classes.heading}>Booking Details</div>

            <div className={classes.gridContainer}>
                <InfoText className={classes.captitalize} placeholder="Service" text={props.booking.tariff_description} icon="settings_applications" />
                <InfoText placeholder="Contact No." text={props.booking.patient.contact_number} icon="phone" />
                <InfoText placeholder="Email ID" text={props.booking.patient.email} icon="email" />
                <InfoText placeholder="Booking ID" text={props.booking.booking_id} icon="book" />
                <InfoText className={classes.captitalize} placeholder="Additional Info" text={props.booking.additional_info} icon="article" />
            </div>
        </div>
    )
};

const useStyles = makeStyles((theme) => ({
    heading: {
        color: theme.palette.primary.main,
        fontSize: '16px',
        fontWeight: 'bold',
        marginBottom: theme.spacing(3)
    },
    gridContainer: {
        display: 'grid',
        gridTemplateColumns: 'auto auto auto',
        columnGap: theme.spacing(5),
        rowGap: `${theme.spacing(3)}px`
    },
    captitalize: {
        textTransform: 'capitalize'
    }
}));

export default BookingDetails;