import React, {useState, useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useSnackbar } from 'notistack';
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import CreateIcon from "@material-ui/icons/Create";
import MaterialTable, { MTableAction } from '@material-table/core';
import Select from "react-select";
import { sharedStyles } from "../../shared/styles";
import Recycle from "../../Recycle";

const ChronicConditionsEnrollment = ({config, props}) => {
    console.log(props)
    const history = useHistory();
    const sharedClasses = sharedStyles();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [employeeChronicConditions, setChronicConditions] = useState([]);

    useEffect(() => {
        setChronicConditions(props.consult_details.chronic_conditions);
    }, []);

    const columns = [
        {
            field: "name",
            editComponent: rowProps => (
                <Select
                    options={props.chronic_conditions}
                    value={rowProps.value || ""}
                    onChange={rowProps.onChange}
                    isOptionDisabled={isOptionDisabled}
                />
            )
        }
    ]

    const isOptionDisabled = (option) => {
        return employeeChronicConditions.some(({name, condition_id}) => {
            return option.value == condition_id;
        })
    }

    const handleConfirm = () => {
        Recycle.rpc({
            model: "AgendaManager",
            method: "set_chronic_conditions_to_consult_details",
            args: [props.consult_id, employeeChronicConditions]
        }).then(res => {
            Recycle.rpc({
                model: "AgendaManager",
                method: "update_or_create_agenda",
                args: [props.consult_id]
            }).then(() => { });

            if (props.tariff_code.startsWith("TSOCPH")) {
                Recycle.rpc({
                    model: "ConsultController",
                    method: "early_consult_complete",
                    args: [props.consult_id]
                }).then(res => {
                    enqueueSnackbar("Consult completed", { variant: "success" });
                    history.push(`/consults/${props.consult_id}/signoffcomplete`);
                }).catch(err => {
                    enqueueSnackbar("Failed to complete consult", { variant: "error" });
                });
            }
            else {
                Recycle.rpc({
                    model: "ConsultController",
                    method: "request_signoff",
                    args: [props.consult_id]
                }).then(res => {
                    enqueueSnackbar("Sign off requested", { variant: "success" });
                    history.push('/dashboard');
                }).catch(err => {
                    enqueueSnackbar("Failed to request sign off", { variant: "error" });
                })
            }
        })
    }

    const Title = () => (
        <div className={classes.title}>
            <div className={sharedClasses.subheading}>Chronic Programme Enrollment</div>
            <div>Chronic Conditions</div>
        </div>
    )

    return (
        <div className={classes.container}>
            <MaterialTable
                title={<Title />}
                data={employeeChronicConditions}
                columns={columns}
                components={{
                    Action: props => {
                        if (props.action.position === "toolbar" && props.action.tooltip === "Add" && !(props.action instanceof Function)) {
                            return (
                                <Tooltip title={props.action.tooltip}>
                                    <Fab className={classes.addButton} color="primary" onClick={props.action.onClick}>
                                        <CreateIcon />
                                    </Fab>
                                </Tooltip>
                            )
                        }
                        else {
                            return <MTableAction {...props} />
                        }
                    }
                }}
                editable={{
                    onRowAdd: newData => {
                        return new Promise((resolve, reject) => {
                            const condition = newData.name;
                            const newCondition = { name: condition.label, condition_id: condition.value}
                            setChronicConditions([...employeeChronicConditions, newCondition]);

                            resolve();
                        })
                    },
                    onRowDelete: oldData => {
                        return new Promise((resolve, reject) => {
                            const newEmployeeChronicConditions = [...employeeChronicConditions];
                            const index = oldData.tableData.id;
                            newEmployeeChronicConditions.splice(index, 1);
                            setChronicConditions(newEmployeeChronicConditions);

                            resolve();
                        })
                    }
                }}
                options={{
                    search: false,
                    sorting: false,
                    actionsColumnIndex: -1
                }}
            />
            <Button className={classes.confirmButton} variant="contained" color="primary" onClick={handleConfirm}>Confirm</Button>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    container: {
        marginTop: theme.spacing(2),
        display: "grid",
        gridAutoFlow: "row",
        gap: theme.spacing(3)
    },
    addButton : {
        position: "relative",
        top: -theme.spacing(4)
    },
    title: {
        paddingTop: theme.spacing(3)
    },
    confirmButton: {
        justifySelf: "end"
    }
}));

export default ChronicConditionsEnrollment;