import React, {useContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';

import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {Button} from "@material-ui/core";
import Box from '@material-ui/core/Box';
import StockTable from "../StockTable";

import ControlledSelect from '../ControlledSelect';
import ConfirmStockDialog from "../ConfirmStockDialog";
import {AppConfigContext} from "../../../App";
import BatchDetailsDialog from "../BatchDetailsDialog";
import {saveStockTake} from "../../../StockManagerConfig";
import {useSnackbar} from "notistack";
import StockTransferIdDialog from "./StockTransferIdDialog";

const TransferStockOut = (props) => {

        const history = useHistory();
        const classes = useStyles();
        const [search, setSearch] = useState("");
        const [valueBeingCaptured, setValueBeingCaptured] = useState('item');
        const [item, setItem] = useState();
        const [uom, setUom] = useState("Units");
        const [selectMenuOpen, setSelectMenuOpen] = useState(false);
        const [unitsReceived, setUnitsReceived] = useState(null);
        const [price, setPrice] = useState(null);
        const [stockItemsCounted, setStockItemsCounted] = useState([]);
        const [completedItems, setCompletedItems] = useState({});
        const [submitting, setSubmitting] = useState(false);
        const [openConfirmStockDialog, setOpenConfirmStockDialog] = React.useState(false);
        const [openStockTransferIdDialog, setOpenStockTransferIdDialog] = React.useState(false);
        const [stockTransferId, setStockTransferId] = React.useState('');
        const [disableQty, setDisableQty] = React.useState(false);
        const [stockItems, setStockItems] = useState([]);
        const [allStockItems, setAllStockItems] = useState([]);
        const appConfig = useContext(AppConfigContext);
        const {enqueueSnackbar} = useSnackbar();

        React.useEffect(() => {

        }, [unitsReceived])

        React.useEffect(() => {
            setAllStockItems(flattenOptionsAvailable())
        }, [stockItems])


        React.useEffect(() => {
            const site = appConfig.user?.dsp

            async function fetchStockItems() {
                const stock = (await props.getStockMoves(site, true, 'transfer_stock_out'));
                setStockItems(stock);
            }

            fetchStockItems();
        }, [])


        const handleOpenConfirmStockDialog = () => {
            setOpenConfirmStockDialog(true);
        };

        const handleCloseConfirmStockDialog = () => {
            setOpenConfirmStockDialog(false);
        };

        const onItemChanged = (item) => {
            setItem(item);
            setSearch("");
            setSelectMenuOpen(false);
            setUnitsReceived(null);
            setPrice(null);

            if (item)
                setValueBeingCaptured('units');
            else
                setValueBeingCaptured('item');
            if (item) {

                if (item.is_batch_item) {
                    setDisableQty(true);
                    setUnitsReceived("0");
                } else
                    setDisableQty(false);
                setUom(item.uom.name)
            } else
                // setUom("Units")
                setDisableQty(false)
        };

        const handleAction = () => {
            if (valueBeingCaptured === 'units' && unitsReceived) {
                addStockTakeItem();
                onItemChanged(null)
            }
        };

        const addStockTakeItem = () => {
            let item_copy = Object.assign({}, item)
            item_copy.quantity = parseFloat(unitsReceived)
            setStockItemsCounted([...stockItemsCounted, {
                'item': item_copy,
            }]);
            setCompletedItems({...completedItems, [item.stock_item_id]: true})

        };

        const removeStockTakeItem = (itemId) => {
            setStockItemsCounted(stockItemsCounted.filter((received) => received.item.stock_item_id !== itemId))
            setCompletedItems({...completedItems, [itemId]: false})
        };

    const updateBatchStock = (batchInformation, stockId, quantitySum, originalGetBatchExpMap) => {
        let data = [...stockItemsCounted];
        const index = data.findIndex(obj => obj.item.stock_item_id === stockId);
        if (index !== -1) {
            data[index].item.batch_items = batchInformation;
            data[index].item.batch_stock_moves_captured = true;
            data[index].item.quantity = quantitySum;
            data[index].item['original_get_batch_exp_map'] = originalGetBatchExpMap;
            setStockItemsCounted(data);
        }
    };

        const filterOptionsAvailable = () => {
            return stockItems.map((category) => {
                return Object.assign({}, category,
                    {
                        options: category.options.filter((option) => (completedItems[option.stock_item_id] === undefined || completedItems[option.stock_item_id] === false))
                    })
            });

        };

        const flattenOptionsAvailable = () => {
            let allOptions = []
            stockItems.forEach(function (category) {
                category.options.forEach(function (value) {
                    allOptions.push(value);
                });
            });
            return allOptions;
        };

        const haveIncompleteItems = (item) => {
            if (!item.item?.is_batch_item) {
                return false;
            } else {
                if (item.item.batch_stock_moves_captured) {
                    return false;
                }
            }
            return true;
        };

        const stockItemsIncompleted = () => {
            return stockItemsCounted.some(haveIncompleteItems)
        };

        async function recordStockTransferOut(stockItems) {
            const site_id = appConfig.user?.dsp;
            await props.recordStockTransferOut(site_id, stockItems).then(r => {
                    const [result_status, result_body] = r
                    if (result_status === true) {
                        enqueueSnackbar("Stock Transfer Out Completed Successfully", {variant: "success"})
                        setSubmitting(false);
                        handleCloseConfirmStockDialog();
                        setStockTransferId(result_body.stock_transfer_id)
                        setOpenStockTransferIdDialog(true)
                    } else {
                        setStockTransferId('')
                        setSubmitting(false);
                        handleCloseConfirmStockDialog();
                        enqueueSnackbar("Unable to process Stock Transfer out", {variant: "error"})
                    }

                }
            )
        }

        const submitStockTake = () => {

            const payload = {
                'site_id_transfer_out': appConfig.user?.dsp,
                'site_name_transfer_out': appConfig.dsp_details.name,
                'stock_transferred_out': stockItemsCounted.map((value => {
                    let item = value.item;
                    return item
                }))
            };
            setSubmitting(true);
            recordStockTransferOut(payload);
        };

        return (
            <>
                <Container className={classes.container}>
                    <NoPaddingCard elevation={2}>
                        <Box display="flex" height="20%" minHeight="120px" flexDirection="column" bgcolor="background.paper">
                            <Box>
                                <h4 style={{marginLeft: "20px"}}>Transfer Stock Out: Add item</h4>
                                <ConfirmStockDialog
                                    textBody="Are you sure you have entered all the stock transferred out?"
                                    open={openConfirmStockDialog}
                                    handleClose={handleCloseConfirmStockDialog}
                                    onDone={submitStockTake}
                                    submitting={submitting}
                                />

                                <StockTransferIdDialog
                                    stockTransferId={stockTransferId}
                                    open={openStockTransferIdDialog}
                                    handleClose={() => {
                                        setOpenStockTransferIdDialog(false);
                                    }}
                                    onDone={() => history.push(`/stock-management`)}
                                />

                                <Box display="flex" alignItems="center"
                                     justifyContent="center"
                                     bgcolor="background.paper">
                                    <Box style={{marginLeft: "20px"}} alignItems="center" width="50%">
                                        <ControlledSelect
                                            options={filterOptionsAvailable()}
                                            search={search}
                                            value={item}
                                            onChange={onItemChanged}
                                            onInputChange={(search) => setSearch(search)}
                                            onBlur={() => setSelectMenuOpen(false)}
                                            menuIsOpen={search !== "" || selectMenuOpen}
                                            onMenuOpen={() => setSelectMenuOpen(true)}
                                            placeholder="Select item"
                                        />
                                    </Box>
                                    <Box alignItems="center" width="30%">
                                        <Grid container direction="row" justify="center" alignItems="flex-end">
                                            {disableQty === false ? <TextField
                                                className={classes.input}
                                                id="standard-basic"
                                                value={unitsReceived == null ? "" : unitsReceived}
                                                onChange={(event) => {
                                                    setUnitsReceived(event.target.value);
                                                }}
                                                // disabled={disableQty}
                                                label={`Enter qty.(${uom})`}
                                                // required={!disableQty}
                                                required={true}
                                                type="number" min="0"
                                            /> : null}
                                        </Grid>

                                    </Box>
                                    <Box alignItems="center" width="20%">
                                        <Button onClick={() => handleAction()} variant="outlined">ADD</Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        {
                            stockItemsCounted.length > 0 ? (
                                <>
                                    <Box display="flex" height="70%" flexDirection="column" bgcolor="background.paper">
                                        <Box>
                                            <StockTable allowBatchEdition={false} updateBatchStock={updateBatchStock}
                                                        stockItems={stockItemsCounted}
                                                        type={"transfer_stock_out"}
                                                        item_identifier={'stock_item_id'}
                                                        removeReceivedItem={removeStockTakeItem}/>
                                        </Box>
                                    </Box>
                                    <Box display="flex" height="10%" flexDirection="column" bgcolor="background.paper">
                                        <Box>
                                            <Grid container direction="row" justify="center" alignItems="center">
                                                <Button
                                                    key="next"
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                    disabled={!(valueBeingCaptured === 'item') || submitting || stockItemsIncompleted()}
                                                    onClick={() => handleOpenConfirmStockDialog()} //submitGoodsReceived()}
                                                    className={classes.doneButton}
                                                >
                                                    Done
                                                </Button>
                                            </Grid>
                                        </Box>
                                    </Box>

                                </>
                            ) : null
                        }

                    </NoPaddingCard>
                </Container>
            </>
        )
    }
;


const Container = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  height: 80vh;
  max-height: 100%;
  display: grid;
  grid-template-rows: 1fr;
  grid-gap: 20px;
  padding: 20px;
`;

const NoPaddingCard = styled(Paper)`
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding: 0 !important;
  max-height: 100%;
  overflow-y: auto;
`;

const ListContainer = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
  }

  ::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #555;
  }
`;

const StockListBody = styled.div`
  padding: 5px;
  margin: 0;
  width: 100%;
  height: 150px;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 60px 1fr 60px;
  grid-template-columns: 1fr;
`;

// const SelectItemBody = styled(CardBody)`
//     display: grid;
//     grid-template-rows: auto 1fr;
//     grid-template-columns: 1fr;
// `;

const useStyles = makeStyles((theme) => ({
    container: {
        maxWidth: "100vw",
        maxHeight: "100vh",
        width: "100vw",
        height: "100vh",
    },
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        maxWidth: "100%",
    },
    input: {
        // marginLeft: theme.spacing(1),
        // flex: 1,
        fontSize: '3em',
        "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            display: "none"
        }
    },
    iconButton: {
        padding: 10,
        width: "200px",
        height: "200px",
    },
    divider: {
        height: 28,
        margin: 4,
    },
    doneButton: {
        width: "100px",
        height: "7%",
        justifyContent: 'center',
        alignSelf: 'center',
    }
}));

export default TransferStockOut;