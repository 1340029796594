import React from 'react';
import TransferStockToAnotherClinic from "../StockManagement/TransferStockToAnotherClinic";
import {getCurrentStockItems, getStockMoves, recordStockTransferOut, saveStockTake} from "../../StockManagerConfig"

const TransferStockToAnotherClinicBase = ({ config, props }) => {
    return (
        <>
            <TransferStockToAnotherClinic
                getCurrentStockItems={getCurrentStockItems}
                getStockMoves={getStockMoves}
                recordStockTransferOut={recordStockTransferOut}
            />
        </>

    )
}

export default TransferStockToAnotherClinicBase;