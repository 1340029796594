import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import { procedureStatusColorMapping } from './const';

const ProcedureDetailsCard = ({ procedure, consult_id }) => {

    const history = useHistory();
    const classes = useStyles({ status: procedure.procedure_status });

    const renderStatusIcon = () => {
        if (procedure.procedure_status === 'pending') {
            return <Icon>access_time</Icon>
        }
        else if (procedure.procedure_status === 'completed') {
            return <Icon>done</Icon>
        }
    }

    const handleRouting = () => {
        history.push(`/consults/${consult_id}/procedure/${procedure.procedure_id}`)
    }

    return (
        <div className={classes.root}>
            <div className={classes.iconContainer}>
                <Icon>{procedure.icon}</Icon>
            </div>

            <div className={classes.detailsContainer}>
                <div>
                    <div className={classes.procedureText}>{procedure.procedure_name}</div>
                    <div className={classes.statusContainer}>{renderStatusIcon()}{procedure.procedure_status}</div>
                </div>
            </div>

            <Button className={classes.button} color="primary" onClick={handleRouting}>
                {procedure.procedure_status === 'completed' ? 'EDIT DETAILS' : 'ADD DETAILS'}
            </Button>
        </div>
    )
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'grid',
        height: '100%',
        gridTemplateAreas: '"icon details details" "button button button"',
        gridTemplateRows: '1fr auto',
        gridTemplateColumns: '2fr 8fr'
    },
    iconContainer: (props) => {
        const statusColor = procedureStatusColorMapping(theme)[props.status];

        return {
            backgroundColor: statusColor.light,
            color: statusColor.main,
            gridArea: 'icon',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }
    },
    detailsContainer: {
        gridArea: 'details',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    procedureText: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        textTransform: 'capitalize'

    },
    statusContainer: (props) => {
        const statusColor = procedureStatusColorMapping(theme)[props.status];

        return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: statusColor.dark,
            textTransform: 'capitalize',
            marginTop: theme.spacing(1)
        }
    },
    button: {
        gridArea: 'button',
        borderTop: '1px solid #979797',
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        height: '50px'
    }
}));

export default ProcedureDetailsCard;