import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ButtonBase from '@material-ui/core/ButtonBase';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { AppConfigContext } from '../App';
import Recycle from '../Recycle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import clsx from 'clsx';

const Header = () => {
    const appConfig = useContext(AppConfigContext);
    const classes = useStyles();

    const handleLogout = () => {
        Recycle.logout();
        appConfig.setState({ ...appConfig, authenticated: false, initialized: false, user: null });
    }

    return (
        <AppBar className={classes.root} position="static">
            <Toolbar style={{ backgroundColor: 'white' }}>
                <div className={classes.logoContainer}>
                    <img className={classes.logo} src="/logo.png" alt="logo" />
                </div>
                <div className={classes.rightContainer}>

                    {appConfig.user ? <ProfileButton user={appConfig.user} onLogout={handleLogout} /> : null}
                </div>
            </Toolbar>
        </AppBar>
    )
};

const ProfileButton = ({ user, onLogout }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [siteMenuOpen, setSiteMenuOpen] = React.useState(false);
    const appConfig = useContext(AppConfigContext);
    let currentDSPReference = appConfig.dsp_details.dsp_reference;

    const showMenu = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const logout = () => {
        handleClose();
        onLogout();
    }

    const changeSite = (site) => {
        localStorage.setItem('preferredSite', site.dsp_reference);
        window.location.reload();
    }

    return (
        <React.Fragment>
            <ButtonBase className={classes.profileButtonContainer} onClick={showMenu}>
                <AccountCircleIcon color="primary" />
                <div className={classes.profile}>
                    <div className={classes.fullName}>{user.name}</div>
                    <div className={classes.role}>{user.role.toUpperCase().replace(/_/g, ' ')}</div>
                </div>
                <ArrowDropDownIcon color="primary" />
            </ButtonBase>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                style: {
                        width: '25ch',
                    },
                }}
            >
                <List>
                    <ListItem button onClick={() => setSiteMenuOpen(!siteMenuOpen) }>
                        Sites
                        {siteMenuOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={siteMenuOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {
                                appConfig.sites.map(function(item) {
                                    let dsp_reference = item.dsp_reference ? item.dsp_reference.split("/") : '';
                                    dsp_reference = dsp_reference.length > 2 ? dsp_reference[dsp_reference.length-2] : null;
                                    if (currentDSPReference === item.dsp_reference){
                                        return (
                                          <ListItem button className={clsx([classes.nested, classes.selected])} onClick={() => changeSite(item)}>
                                              {item.dsp_site_name ? item.dsp_site_name : item.name} ({dsp_reference})
                                          </ListItem>
                                      )
                                    }
                                    else {
                                        return (
                                          <ListItem button className={classes.nested} onClick={() => changeSite(item)}>
                                              {item.dsp_site_name ? item.dsp_site_name : item.name} ({dsp_reference})
                                          </ListItem>
                                      )
                                    }
                                })
                          }
                        </List>
                    </Collapse>
                    <ListItem button onClick={logout}>
                      Logout
                    </ListItem>
                </List>
            </Menu>
        </React.Fragment>
    )
};

const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: 'none'
    },
    nested: {
        paddingLeft: theme.spacing(4)
    },
    selected: {
      backgroundColor: '#e4e6ed'
    },
    logoContainer: {
        flex: 1
    },
    logo: {
        height: '56px'
    },
    rightContainer: {
        display: 'flex',
    },
    profileButtonContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    profile: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        textTransform: 'capitalize',
        textAlign: 'left'
    },
    role: {
        color: '#999999',
        fontSize: '9px'
    },
    fullName: {
        color: theme.palette.primary.main,
        fontSize: '12px'
    },
    divider: {
        height: '24px',
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1)
    },
    bellContainer: {
        marginRight: theme.spacing(3)
    }
}));

export default Header;