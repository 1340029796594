import React from 'react';
import styled from 'styled-components';
import Select from "react-select";


const ControlledSelect = ({
                              options,
                              search,
                              value,
                              onChange,
                              onInputChange,
                              onBlur,
                              menuIsOpen,
                              onMenuOpen,
                              placeholder,
                              isOptionDisabled
                          }) => {

    return (
        <StyledSelect
            options={options}
            formatGroupLabel={formatGroupLabel}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            inputValue={search}
            value={value}
            onChange={onChange}
            onInputChange={onInputChange}
            onBlur={onBlur}
            menuIsOpen={menuIsOpen}
            onMenuOpen={onMenuOpen}
            maxMenuHeight={235}
            styles={customSelectStyles}
            placeholder={placeholder}
            isOptionDisabled={isOptionDisabled || false}
            isClearable
        />
    );
};


// [IM]: Hack to fix Select rendering issue when item is selected and controlled search value changes.
const StyledSelect = styled(Select)`
    input {
        opacity: ${props => props.inputValue !== "" ? "1" : "0"} !important;
    },
`;

const formatGroupLabel = data => {
    return (
        <SelectGroup>
            <span>{data.name}</span>
            <SelectGroupLabel>{data.options.length}</SelectGroupLabel>
        </SelectGroup>
    );
};

const SelectGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const SelectGroupLabel = styled.span`
    background-color: #EBECF0;
    border-radius: 2em;
    color: #172B4D,
    display: inline-block;
    line-height: 1;
    min-width: 1;
    padding: 0.16666666666667em 0.5em;
    text-align: center;
`;


const customSelectStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted #EEEEEE',
        color: state.isSelected ? '#1E1E2E' : 'inherit',
        padding: 25,
        opacity: state.isDisabled ? 0.5 : 1,
        transition: 'opacity 300ms'
    }),
    control: (provided, state) => ({
        // none of react-select's styles are passed to <Control />
        ...provided,
        height: '2.1em',
        borderRight: "none",
        borderLeft: "none",
        borderTop: "none",
        boxShadow: "none",
        borderRadius: "0%",
        borderColor: "#949494",
        zIndex: 100
    }),
    indicatorSeparator: (styles) => ({display:'none'}),
    input: (provided, state) => ({
        ...provided,
        fontSize: '1.1em',

    }),
    placeholder: (provided, state) => ({
        ...provided,
        fontSize: '1.1em'
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return {...provided, opacity, transition, fontSize: '1.1em'};
    },
    menu: base => ({
        ...base,
        zIndex: 100,
    })
}

export default ControlledSelect;