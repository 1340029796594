import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import PdfViewer from "./PdfViewer";

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        gridTemplateColumns: '1fr',
        maxHeight: '100vh',
        overflow: 'hidden'
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DocumentViewerDialog(props) {
    const classes = useStyles();
    const [scale, setScale] = React.useState(1.0)

    const handleClose = () => {
        setScale(1.0)
        props.setClose()
    };

    const handleZoomOut = () => {
        setScale(prevState => Math.max(0.5, prevState - 0.5))
    };

    const handleZoomIn = () => {
        setScale(prevState => prevState + 0.5)
    };

    return (

        <Dialog fullScreen open={props.open} onClose={handleClose} TransitionComponent={Transition}>
            <div className={classes.container}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon/>
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {props.title}
                        </Typography>
                        <IconButton edge="start" color="inherit" onClick={handleZoomOut} aria-label="close">
                            <ZoomOutIcon/>
                        </IconButton>
                        <IconButton edge="start" color="inherit" onClick={handleZoomIn} aria-label="close">
                            <ZoomInIcon/>
                        </IconButton>


                    </Toolbar>
                </AppBar>
                <PdfViewer scale={scale} data={props.data}
                           download_filename={props.title}/>
            </div>


        </Dialog>
    );
}

