import React from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {sharedStyles} from '../shared/styles';
import dayjs from 'dayjs';
import InfoText from './InfoText';
import Divider from '@material-ui/core/Divider';
import clsx from 'clsx';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import {calculateAge} from '../shared/utils';
import {useHistory} from "react-router-dom";

const ConsultDetails = ({config, props}) => {
    const classes = useStyles();
    const sharedClasses = sharedStyles();

    return (
        <div>
            <div className={classes.top}>
                <img className={classes.profileImage} src={`/${props.consult.client_id}_logo.png`} alt="profile"/>

                <div className={classes.consultDetails}>
                    <div
                        className={sharedClasses.heading}>{props.consult.patient.first_name} {props.consult.patient.last_name}</div>
                    <div
                        className={clsx([classes.identifier, sharedClasses.helperText])}>ID: {props.consult.patient.identifier}</div>

                    <div className={clsx([sharedClasses.helperText, classes.service])}>
                        <div>Service: {props.consult.tariff_description}</div>
                        <Divider className={classes.verticalDivider} orientation="vertical" flexItem/>
                        <div>Arrived on: {dayjs(props.consult.arrived_at).format('hh:mm A')}</div>
                    </div>

                </div>
            </div>

            <Divider className={classes.divider} variant="middle"/>

            <div className={classes.rightContainer}>
                <BasicInformation patient={props.consult.patient}/>
            </div>

            <NotesPopup consult_id={props.consult?.consult_id}/>
        </div>
    )
};

const BasicInformation = ({patient}) => {
    const classes = useStyles();

    return (
        <div>
            <div className={classes.heading}>Basic Information</div>

            <div className={classes.infoContainer}>
                <InfoText className={classes.infoText} placeholder="Gender" capitalize={true} text={patient.gender}/>
                <InfoText className={classes.infoText} placeholder="Age" text={calculateAge(patient.date_of_birth)}/>
                <InfoText className={classes.infoText} placeholder="Contact No." text={patient.contact_number}/>
                <InfoText className={classes.infoText} placeholder="Email ID" text={patient.email}/>
            </div>
        </div>
    )
}

const NotesPopup = ({consult_id}) => {
    const classes = useStyles();
    const history = useHistory();

    const toolTipText = <div className={classes.notesTooltipText}>Important information to note about the clinical
        assessment</div>

    function BootstrapTooltip(props) {
        const classes = useStylesBootstrap();

        return <Tooltip arrow classes={classes} {...props} />;
    }

    const handleRouting = () => {
        history.push(`/consults/${consult_id}/info`)
    }

    return (
        <div className={classes.rightContainer}>
            <Divider className={classes.divider} variant="fullWidth"/>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
            >
                <div className={classes.notesHeading}>Instruction Notes</div>
                <BootstrapTooltip arrow placement="right" title={toolTipText}>
                    <InfoOutlinedIcon/>
                </BootstrapTooltip>
            </Grid>
            <div onClick={handleRouting} className={classes.notesInfo}>REVIEW DETAILS</div>
            <div className={classes.infoContainer}>
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    top: {
        display: 'flex',
        textTransform: 'capitalize'
    },
    consultDetails: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        alignItems: 'center',
        flex: 1,
        marginLeft: theme.spacing(2)
    },
    profileImage: {
        height: '82px',
        // width: '82px'
    },
    identifier: {
        textAlign: 'end'
    },
    heading: {
        color: theme.palette.primary.main,
        fontSize: '16px',
        fontWeight: 'bold',
        marginBottom: theme.spacing(3)
    },
    infoText: {
        padding: theme.spacing(3),
        '&:not(:first-child)': {
            borderLeft: '1px solid #EEEEEE'
        }
    },
    infoContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    service: {
        display: 'flex',
    },
    verticalDivider: {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2)
    },
    notesHeading: {
        color: theme.palette.primary.main,
        fontSize: '16px',
        fontWeight: 'bold',
        paddingRight: '6px',
    },
    notesInfo: {
        marginTop: '6px',
        color: 'rgba(0, 0, 0, 0.36)',
        fontSize: '14px',
        cursor: 'pointer',
        '&:hover': {
            color: "black",
        },
    },
    notesTooltipText: {
        color: 'rgba(0, 0, 0, 0.46)',
        fontSize: '15px',
        fontStyle: 'italic',
    }
}));

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        fontSize: 16,
        width: 17,
        "&::before": {
            border: "1px solid #000",
            backgroundColor: "#fff",
            boxSizing: "border-box"
        }
    },
    tooltip: {
        background: "#fff",
        color: "#000",
        border: "1px solid #000",
        borderRadius: 3
    },
}));

export default ConsultDetails;