import React from 'react';
import StockTake from "../StockManagement/StockTake";
import {getCurrentStockItems, getGRVStockMoves, saveStockTake} from "../../StockManagerConfig"
import ReceiveSupplierStock from "../StockManagement/ReceiveSupplierStock";

const StockTakeBase = ({ config, props }) => {
    return (
        <>
            <StockTake
                getCurrentStockItems={getCurrentStockItems}
                saveStockTake={saveStockTake}
            />
        </>

    )
}

export default StockTakeBase;