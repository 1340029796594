import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SunIcon from '@material-ui/icons/WbSunny';
import { AppConfigContext } from '../App';
import { sharedStyles } from '../shared/styles';
import clsx from 'clsx';

const Greetings = ({ config, props }) => {
    const appConfig = useContext(AppConfigContext);
    const sharedClasses = sharedStyles();
    const classes = useStyles();
    let dsp_reference = appConfig.dsp_details.dsp_reference ? appConfig.dsp_details.dsp_reference.split("/") : '';
    dsp_reference = dsp_reference.length > 2 ? dsp_reference[dsp_reference.length-2] : null;

    return (
        <>
            <div className={clsx([classes.container, sharedClasses.heading])}>
                <SunIcon className={classes.icon} />
                <div className={classes.hello}>Hello,</div>
                <div>{appConfig.user.name}</div>
            </div>
            <div className={classes.site}>{appConfig.dsp_details.dsp_site_name ? appConfig.dsp_details.dsp_site_name : appConfig.dsp_details.name} ({dsp_reference})</div>
        </>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center'
    },
    icon: {
        color: '#F6BA3B',
        fontSize: '45px',
        marginRight: theme.spacing(1)
    },
    hello: {
        fontWeight: 'bold',
        marginRight: theme.spacing(2)
    },
    site: {
        fontWeight: 'bold',
        // color: '#1D3440'
        color: theme.palette.primary.main,
        fontSize: '20px',
    }
}));

export default Greetings;