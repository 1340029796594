import React from 'react';

const ProviderCell = ({ history }) => {
    return (
        <div>
            <div>{history.dsp ? history.dsp.name: null}</div>
            <div>{history.provider_speciality}</div>
        </div>
    )
};

export default ProviderCell;