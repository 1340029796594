import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import DynamicForm from './DynamicForm';

const ConsultsSearch = ({ config, props }) => {
    const history = useHistory();
    const classes = useStyles();

    const handleSearch = (formValues) => {
        const { identifier_type, identifier } = formValues;

        history.push(`/consult_search_results/${identifier_type}/${identifier}`);
    }

    const renderSearchButton = ({ submitting }) => (
        <Button key="search" variant="contained" color="primary" type="submit" disabled={submitting}>Search</Button>
    )

    return (
        <Paper>
            <div className={classes.imageContainer}><img src="/search.png" alt="search" /></div>
            <div className={classes.formContainer}>
                <DynamicForm onSubmit={handleSearch}
                    config={{ fields: searchForm, defaultValues: {} }}
                    actions={[renderSearchButton]}
                />
            </div>
        </Paper>
    )
}

const useStyles = makeStyles((theme) => ({
    formContainer: {
        padding: theme.spacing(3)
    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#EBEEF0'
    }
}));

const searchForm = {
    "id": "CONSULTS_SEARCH",
    "name": "Search Consults",
    "groups": [
        {
            "id": "part_1",
            "name": "Search Patient",
            "fields": [
                {
                    "id": "identifier_type",
                    "name": "Identity Type",
                    "type": "radio",
                    "default": "SAID",
                    "optional": false,
                    "display_view": "radio",
                    "render_condition": [],
                    "calculation_precondition": [],
                    "calculation": [],
                    "options": [
                        {
                            "id": "SAID",
                            "name": "ID Number"
                        },
                        {
                            "id": "PASSPORT",
                            "name": "Passport Number"
                        }
                    ]
                },
                {
                    "id": "identifier",
                    "name": "ID Number",
                    "type": "text",
                    "default": "None",
                    "optional": false,
                    "display_view": "text",
                    "validation": [
                        "valid_said",
                        "get", "identifier"
                    ],
                    "render_condition": ["=", "get", "identifier_type", "SAID"],
                    "calculation_precondition": [],
                    "calculation": [],
                    "options": []
                },
                {
                    "id": "identifier",
                    "name": "Passport Number",
                    "type": "text",
                    "default": "None",
                    "optional": false,
                    "display_view": "text",
                    "render_condition": ["=", "get", "identifier_type", "PASSPORT"],
                    "calculation_precondition": [],
                    "calculation": [],
                    "options": []
                }
            ]
        }
    ]
}

export default ConsultsSearch;