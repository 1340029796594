import React from 'react';
import { useHistory } from 'react-router-dom';
import MaterialTable from '@material-table/core';
import Button from '@material-ui/core/Button';
import StatusCell from './StatusCell';
import PatientDetailsCell from './PatientDetailsCell';
import ArrivalCell from './ArrivalCell';
import Recycle from '../../Recycle';
import { sharedStyles } from '../../shared/styles';

const ConsultsTable = ({ config, props }) => {
    const history = useHistory();
    const sharedClasses = sharedStyles();

    const columns = [
        { field: 'status', title: 'STATUS', render: (rowData) => <StatusCell consult={rowData} /> },
        {
            field: 'patient_detail',
            title: 'PATIENT DETAILS',
            customFilterAndSearch: (term, rowData) => `${rowData.patient.first_name} ${rowData.patient.last_name}`.toLowerCase().includes(term.toLowerCase()),
            render: (rowData) => <PatientDetailsCell consult={rowData} />
        },
        { field: 'arrival', title: 'ARRIVAL TIME', render: (rowData) => <ArrivalCell consult={rowData} /> },
    ];

    const handleClick = (consult) => {
        if (consult.consult_status === 'not_started') {
            Recycle.rpc({
                model: "ConsultController",
                method: "start_consult",
                args: [consult.consult_id]
            }).then((res) => {
                history.push(`/consults/${consult.consult_id}`);
            });
        } else {
            history.push(`/consults/${consult.consult_id}`);
        }
    }

    return (
        <MaterialTable
            data={props.consults}
            columns={columns}
            title={<div className={sharedClasses.subheading}>{config.title || props.title || "Consults"}</div>}
            actions={[
                (rowData) => ({
                    label: "Consult",
                    onClick: handleClick,
                    hidden: ["not_started", "in_progress", "procedures_completed"].indexOf(rowData.consult_status) < 0
                }),
                (rowData) => ({
                    label: "Finalise",
                    onClick: handleClick,
                    hidden: rowData.consult_status !== 'awaiting_omp'
                })
            ]}
            components={{
                Action: (props) => {
                    const buttonProps = props.action.action(props.data);
                    return buttonProps.hidden ? null : <Button color="primary" variant="contained"
                        style={{ marginRight: 15 }}
                        onClick={() => buttonProps.onClick(props.data)}>{buttonProps.label}
                    </Button>
                }
            }}
            options={{
                headerStyle: {
                    color: '#999999',
                    fontSize: '11px',
                    fontWeight: 600,
                    lineHeight: '15px'
                },
                actionsColumnIndex: -1,
                searchFieldStyle: { marginRight: "48px" }
            }}
        />
    )
}

export default ConsultsTable;