import React from 'react';
import ReceiveSupplierStock from "../StockManagement/ReceiveSupplierStock";
import {getGRVStockMoves, getStockItems, saveStockReceived} from "../../StockManagerConfig"

const ReceiveSupplierStockBase = ({ config, props }) => {
    return (
        <>
            <ReceiveSupplierStock
                getStockItems={getStockItems}
                getGRVStockMoves={getGRVStockMoves}
                saveStockReceived={saveStockReceived}
            />
        </>

    )
}

export default ReceiveSupplierStockBase;