import React, {useEffect} from "react";
import { useHistory } from "react-router-dom";
import Recycle from "../../Recycle";

const AgendaConsultLogic = ({config, props}) => {
    const history = useHistory();
    
    useEffect(() => {
        if (props.dsp_details.is_agenda_site) {
            const { consult_details } = props;

            if (consult_details && consult_details.message && consult_details.message === "Consult details not found") {
                history.push(`/agenda-search/${props.consult_id}/${props.patient_identifier}`);
            }
            else if (consult_details.employee_exists) {
                Recycle.rpc({
                    model: "AgendaManager",
                    method: "get_employee",
                    args: [consult_details.patient_identifier]
                }).then(employee => {
                    if (employee.employment_site_id === consult_details.employment_site_id && employee.employee_role_id === consult_details.employee_role_id && !consult_details.selected_event) {
                        history.push(`/agenda-events/${consult_details.consult_id}/${consult_details.patient_identifier}`);
                    }
                })
            }
        }
        // Checks sms?
    }, []);
    return null;
}

export default AgendaConsultLogic;