import React, {useContext, useEffect} from 'react';
import MaterialTable from "@material-table/core";
import {makeStyles} from '@material-ui/core/styles';
import useInterval from "../../hooks/useInterval"
import Chip from "@material-ui/core/Chip";
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import Recycle from "../../Recycle";
import DocumentViewerDialog from "../DocumentViewer/DocumentViewerDialog";

import {Button} from "@material-ui/core";
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import LocalPharmacyIcon from '@material-ui/icons/LocalPharmacy';
import NotesIcon from '@material-ui/icons/Notes';
import CreateIcon from '@material-ui/icons/Create';
import GenerateDocumentDialog from "../GenerateDocumentDialog";
import TitleTypeDocumentCell from "./TitleTypeDocumentCell";


const ClinicalDocumentsTable = ({config, props}) => {
    const classes = useStyles();
    const [tableData, setTableData] = React.useState(props.clinical_documents);
    const [tableLoading, setTableLoading] = React.useState(false);
    const [isTimerRunning, setIsTimerRunning] = React.useState(true)
    const [openClinicalDocument, setOpenClinicalDocument] = React.useState(false);
    const [openPdfViewer, setOpenPdfViewer] = React.useState(false);
    const [pdfB64String, setPdfB64String] = React.useState('');
    const [documentTitle, setDocumentTitle] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [hidden, setHidden] = React.useState(false);
    const [clinicalDocumentType, setClinicalDocumentType] = React.useState('');

    const handleVisibility = () => {
        setHidden((prevHidden) => !prevHidden);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    useInterval(() => {
        // Your custom logic here
        Recycle.rpc({
            model: "ConsultController",
            method: "get_clinical_documents_for_consult",
            args: [props.consult_id]
        }, false).then((res) => {
            setTableData(res)
            if (res.every((v) => v.document_status === 'generated') === true) {
                setIsTimerRunning(false)
            } else {
                setIsTimerRunning(true)

            }
        }).catch((err) => {

        })
    }, isTimerRunning ? 2500 : null);

    const handleOpenPDF = () => {
        setOpenPdfViewer(true)

    };

    const setClosePdfViewer = () => {
        setOpenPdfViewer(false)

    }

    const handleCloseReferralNote = () => {
        setOpenClinicalDocument(false)
    }

    const handleSubmitClinicalDocument = (title, documentType, metadata) => {

        setOpenClinicalDocument(false)
        Recycle.rpc({
            model: "ConsultController",
            method: "generate_clinical_document",
            args: [props.consult_id, title, documentType, metadata]
        }).then((res) => {
        }).catch((err) => {

        })
        setIsTimerRunning(true)

    }

    const actions = [
        {icon: <NoteAddIcon/>, name: 'Referral Note', type: 'REFERRAL_NOTE', bgColor: "#f50057",},
        {icon: <NotesIcon/>, name: 'Sick Note', type: 'SICK_NOTE', bgColor: "#d500f9",},
        {icon: <LocalPharmacyIcon/>, name: 'Script', type: 'SCRIPT', bgColor: "#ffc400",},
    ];

    const closeDialog = () => {
        setOpenClinicalDocument(false)
    }

    const handleOpenDocument = (rowData) => {
        Recycle.rpc({
            model: "DocumentController",
            method: "get_document_as_base64",
            args: [props.consult_id, rowData.document_id]
        }).then((res) => {
            setPdfB64String(res.document_b64)
            handleOpenPDF()
            setDocumentTitle(rowData.document_title)
        }).catch((err) => {

        })
    }

    const columns = [
        {
            field: 'document_title',
            title: 'TITLE',
            render: (rowData) => <TitleTypeDocumentCell cell={rowData}/>
        },
        {
            field: 'created_by',
            title: 'CREATED BY',
        },
        {
            field: 'document_status',
            title: 'STATUS',
            render: (rowData) => <Chip
                color={rowData.document_status === "generated" ? "default" : rowData.document_status === "error" ? "secondary" : "default"}
                style={{textTransform: "capitalize"}} label={rowData.document_status}/>

        },

    ]
    return (
        <><MaterialTable
            data={tableData}
            columns={columns}
            title={config.title ? config.title : "Generate Clinical Documents"}
            isLoading={tableLoading}
            actions={[
                {
                    icon: () => <SpeedDial
                        ariaLabel="Generate Clinical Document"
                        className={classes.speedDial}
                        hidden={hidden}
                        icon={<CreateIcon/>}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        open={open}
                    >
                        {actions.map((action) => (
                            <SpeedDialAction
                                key={action.name}
                                icon={action.icon}
                                tooltipTitle={action.name}
                                classes={classes}
                                FabProps={{
                                    style: { backgroundColor: action.bgColor, color: "white" }
                                }}
                                tooltipOpen
                                TooltipClasses={classes}
                                onClick={(event) => {
                                    setClinicalDocumentType(action.type)
                                    setOpenClinicalDocument(true);
                                }}/>
                        ))}
                    </SpeedDial>,
                    tooltip: 'Create Clinical Document',
                    isFreeAction: true,
                },
                rowData => ({
                    icon: () => <Button color="primary" variant="contained"
                                        style={{marginRight: 15}}> View </Button>,
                    tooltip: 'View document',
                    onClick: (event, rowData) => handleOpenDocument(rowData),
                    disabled: rowData.document_status !== 'generated'
                })

            ]}

            options={{
                headerStyle: {
                    color: '#999999',
                    fontSize: '11px',
                    fontWeight: 600,
                    lineHeight: '15px'
                },
                search: false,
                actionsColumnIndex: -1

            }}
        />
            <GenerateDocumentDialog
                open={openClinicalDocument}
                closeDialog={closeDialog}
                handleSubmitClinicalDocument={handleSubmitClinicalDocument}
                clinicalDocumentType={clinicalDocumentType}
                handleCloseReferralNote={handleCloseReferralNote}/>
            <DocumentViewerDialog title={documentTitle} open={openPdfViewer} data={pdfB64String}
                                  setClose={setClosePdfViewer}/>
        </>

    )
};

const useStyles = makeStyles((theme) => ({
    speedDial: {
        position: 'absolute',
        bottom: theme.spacing(1),
        right: theme.spacing(2),
    },
    fab: {
        // margin: theme.spacing(0.5),
        // marginLeft: theme.spacing(-0.5)
    },
    staticTooltipLabel: {
        width: '100px'

    },


    // actionsContainer: {
    //     display: 'grid',
    //     gridTemplateColumns: 'auto auto',
    //     columnGap: theme.spacing(1),
    //     width: '224px',
    //     marginLeft: 'auto',
    //     marginRight: 'auto'
    // }
}));

export default ClinicalDocumentsTable;