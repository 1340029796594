import React from 'react';
import dayjs from 'dayjs';
import { makeStyles } from '@material-ui/core/styles';

const ServiceAndDateCell = ({ history }) => {
    const classes = useStyles();

    return (
        <div>
            <div className={classes.description}>{history.tariff_description.replace(/_/g, ' ')}</div>
            <div className={classes.date}>{dayjs(history.ended_at).format('DD/MM/YYYY')}</div>
        </div>
    )
};

const useStyles = makeStyles((theme) => ({
    description: {
        fontSize: '14px',
        fontWeight: 600,
        textTransform: 'capitalize'
    },
    date: {
        fontSize: '11px',
        color: '#666666',
        lineHeight: '15px',
        textTransform: 'capitalize'
    }
}))

export default ServiceAndDateCell;