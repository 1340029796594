import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import dayjs from 'dayjs';
import { consultStatusColorMapping } from '../const';

const StatusCell = ({ consult }) => {
    const classes = useStyles({ status: consult.consult_status });

    return (
        <div className={classes.root}>
            <div className={classes.statusRectangle}></div>
            <div>
                <div className={classes.status}>{consult.consult_status.replace(/_/g, ' ')}</div>
                <div className={classes.arrival}>{dayjs(consult.arrived_at).format('ddd, DD/MM/YYYY')}</div>
            </div>
        </div>
    )
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center'
    },
    statusRectangle: (props) => {
        const backgroundColor = consultStatusColorMapping(theme)[props.status].main;

        return {
            width: '4px',
            height: '24px',
            position: 'absolute',
            left: 0,
            backgroundColor: backgroundColor
        }
    },
    status: (props) => {
        const color = consultStatusColorMapping(theme)[props.status].main;

        return {
            fontSize: '14px',
            fontWeight: 600,
            color: color,
            textTransform: 'capitalize'
        }

    },
    arrival: {
        fontSize: '11px',
        color: '#666666',
        lineHeight: '15px'
    }
}));

export default StatusCell;