import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import MaterialTable from "@material-table/core";
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import PatientCell from './PatientCell';
import ScheduleDatetimeCell from './ScheduleDatetimeCell';
import ServiceCell from './ServiceCell';
import StatusCell from './StatusCell';
import Recycle from '../../Recycle';
import {makeStyles} from "@material-ui/core/styles";
import {sharedStyles} from '../../shared/styles';


const BookingsTable = ({config, props, refresh}) => {
    const sharedClasses = sharedStyles();
    const tableRef = React.createRef();
    const [orderBy, setOrderBy] = React.useState(-1)
    const [orderDirection, setOrderDirection] = React.useState("")
    const [page, setPage] = React.useState(0)

    const columns = [
        {
            field: 'fullName',
            title: 'Patient',
            customFilterAndSearch: (term, rowData) => rowData.patient.name.toLowerCase().includes(term.toLowerCase()),
            customSort: (a, b) => a.patient.name > b.patient.name ? -1 : 1,
            render: (rowData) => <PatientCell appointment={rowData}/>
        },
        {
            field: 'appointment_datetime',
            title: 'Day & Date',
            searchable: false,
            customSort: (a, b) => (new Date(a.scheduled_at)) > (new Date(b.scheduled_at)) ? -1 : 1,
            render: (rowData) => <ScheduleDatetimeCell datetime={rowData.scheduled_at}/>
        },
        {
            field: 'service',
            title: 'Service',
            customFilterAndSearch: (term, rowData) => rowData.tariff_description.includes(term),
            customSort: (a, b) => a.tariff_description > b.tariff_description ? -1 : 1,
            render: (rowData) => <ServiceCell service={rowData.tariff_description}/>
        },
        {field: 'status', title: 'Status', render: (rowData) => <StatusCell status={rowData.status}/>},
        {
            field: 'actions',
            title: 'Actions',
            render: (rowData) => rowData.status === 'pending' ?
                <ActionsButton booking={rowData} refresh={refresh}/> : null
        }
    ]

    React.useEffect(() => {
        if (!tableRef.current || !props.bookings)
            return;

        tableRef.current.dataManager.setData(props.bookings);
        tableRef.current.onChangeOrder(orderBy, orderDirection)
        tableRef.current.onChangePage(null, page)

        // NB: page, orderBy and orderDirection intentionally left out of dep array
    }, [props.bookings, tableRef])

    const updateOrderBy = (newOrderBy, newOrderDirection) => {
        setOrderBy(newOrderBy);
        setOrderDirection(newOrderDirection);
    }

    return (
        <MaterialTable
            tableRef={tableRef}
            data={[]}
            columns={columns}
            onOrderChange={updateOrderBy}
            onChangePage={(page, pageSize) => setPage(page)}
            title={<div className={sharedClasses.subheading}>{config.title || props.title || "Appointments"}</div>}
            options={{
                headerStyle: {
                    color: '#999999',
                    fontSize: '11px',
                    fontWeight: 600,
                    lineHeight: '15px'
                },
                actionsColumnIndex: -1,
                minBodyHeight: '100%',
                searchFieldStyle: {marginRight: "48px"}
            }}
        />
    )
};

const ActionsButton = ({booking, refresh}) => {

    const classes = useStyles();

    const history = useHistory();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleRegister = () => {
        handleClose();
        history.push(`/appointments/${booking.booking_id}`);
    }

    const handleNoShow = () => {
        Recycle.rpc({
            model: "BookingController",
            method: "update_booking_status",
            args: ["no_show", booking.booking_id]
        }).then(res => {
            refresh();
        });
    }

    return (
        <React.Fragment>
            <IconButton onClick={handleClick} className={classes.actions}><MoreHorizIcon/></IconButton>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={handleRegister}>Arrive</MenuItem>
                <MenuItem onClick={handleNoShow}>No Show</MenuItem>
            </Menu>
        </React.Fragment>
    )
}

const useStyles = makeStyles((theme) => ({
    actions: {
        padding: 0
    },
    s: {
        marginRight: "24px"
    }
}));

export default BookingsTable;