import React from 'react';
import {Field} from 'react-final-form';
import FieldLayout from './FieldLayout'
import Select from 'react-select';
import {makeStyles} from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";


const ReactSelectAdapter = ({input, ...rest}) => {

    const classes = useStyles();

    const {onChange, ...restInput} = input;
    const [selectedOption, setSelectedOption] = React.useState([]);

    React.useEffect(() => {

        const optionsFromIDs = getObjectsfromValues(restInput.value, rest.options)

        if (optionsFromIDs !== undefined) {
            setSelectedOption(optionsFromIDs);
        } else {
            setSelectedOption(null);
        }
    }, [restInput.value, rest.options])


    const internalOnChange = (e) => {
        let values = Array.isArray(e) ? e.map(x => x.value) : []
        onChange(values)
    }

    const getObjectsfromValues = (values, options) => {
        let value_object = []
        if (Array.isArray(values)) {
            for (let value of values) {
                value_object.push(options.find(o => o.value === value))
            }
        }
        return value_object
    }

    return (
        <>
            <InputLabel shrink={true}>{rest.label}</InputLabel>
            <Select
                {...restInput}
                {...rest}
                styles={customStyles}
                isClearable={true}
                isSearchable={true}
                onChange={internalOnChange}
                value={selectedOption}
                isRequired
                isMulti
            />
            {
                // Hack - Select does not support required
                <input
                    tabIndex={-1}
                    autoComplete="off"
                    style={{opacity: 0, height: 0}}
                    value={Array.isArray(selectedOption) ? selectedOption : []}
                    required={true}
                    // readOnly
                    onChange={() => {
                    }}
                />
            }
        </>);
}

const MultiSelectField = ({id, name, optional, options, unit, description, warning}) => {
    let label = name;
    let adornment = unit ? " ".concat("(", unit, ")") : "";
    let mapped_options = options.map(({id, name}) => ({label: name.concat(adornment), value: id}));

    return (
        <FieldLayout description={description} warning={warning}>
            <Field name={id}
                   label={label}
                   optional={optional}
                   options={mapped_options}
                   component={ReactSelectAdapter}
                   adornment={adornment}>
            </Field>
        </FieldLayout>
    )
}

const useStyles = makeStyles((theme) => {
    return {
        label: {
            color: theme.palette.grey[600],
            fontSize: '0.9rem'
        },
    }
});

const customStyles = {
    control: (provided, state) => {
        return {
            ...provided,
            background: 'none',
            border: 'none',
            borderColor: 'none',
            boxShadow: 'none',
            borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
            borderRadius: 0
        }
    }
}

export default MultiSelectField;