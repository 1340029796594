import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import StockManagementMenuCard from './StockManagementMenuCard';

const StockManagementMenu = ({config, props: {}}) => {
    const classes = useStyles();

    const renderProcedureCards = () => {
        return (
            <div className={classes.container}>
                <Paper className={classes.detailsMainContainer}>
                    <StockManagementMenuCard icon="arrow_downward" path="receive-supplier-stock" stock_option_selected="Receive supplier stock"/>
                </Paper>
                <Paper className={classes.detailsMainContainer}>
                    <StockManagementMenuCard icon="note_add" path="take-stock" stock_option_selected="Take stock"/>
                </Paper>
                <Paper className={classes.detailsMainContainer}>
                    <StockManagementMenuCard icon="exit_to_app" path="transfer-stock-to-another-clinic" stock_option_selected="Transfer stock to another clinic"/>
                </Paper>
                <Paper className={classes.detailsMainContainer}>
                    <StockManagementMenuCard icon="exit_to_app" path="transfer-stock-in-from-another-clinic" flip={true} stock_option_selected="Receive stock from another clinic"/>
                </Paper>
                <Paper className={classes.detailsMainContainer}>
                    <StockManagementMenuCard icon="edit" path="adjust-stock" stock_option_selected="Record damages/ losses"/>
                </Paper>
            </div>
        )
    }

    return (
        <Paper className={classes.detailsRootContainer}>
            <div className={classes.helperText}>What would you like to do?</div>
            {renderProcedureCards()}
        </Paper>
    )
}

const useStyles = makeStyles((theme) => ({
    helperText: {
        fontSize: '34px',
        paddingBottom: "40px",
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(2)
    },
    container: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gap: `${theme.spacing(3)}px`
    },
    detailsMainContainer: {
        height: '138px'
    },
    detailsRootContainer: {
        padding: "40px 20px 80px 20px"
    }
}));

export default StockManagementMenu;