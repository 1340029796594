import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

export default function ConfirmStockDialog({textBody, open, handleClose, onDone, submitting}) {
    const classes = useStyles();

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent style={{paddingTop: '5px'}}>
                    <DialogTitle style={{paddingLeft:'0px'}}>
                        Confirmation
                    </DialogTitle>
                    <DialogContentText  id="alert-dialog-description">
                        {textBody}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Grid container direction="row" justify="flex-start" alignItems="center">
                        <Button className={classes.doubleCheckButton} variant={"contained"} onClick={handleClose} color="default" autoFocus>
                            No, let me double check
                        </Button>
                    </Grid>

                    <Grid container direction="row" justify="flex-end" alignItems="center">
                        <Button
                            key="next"
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={onDone}
                            className={classes.doneButton}
                        >
                            YES
                        </Button>
                    </Grid>
                    <Button disabled={submitting} onClick={handleClose} color="primary">

                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    actions: {
        padding: 0
    },
    s: {
        marginRight: "24px"
    },
    doubleCheckButton: {
        width: "300px",
        fontSize: '15px',
        color: "grey",
    },
    doneButton: {
        width: "100px",
        height: "7%",
        // fontSize: '30px',
        justifyContent: 'center',
        alignSelf: 'center',
    },
    subheading: {
        fontSize: '26px',
        marginBottom: theme.spacing(2)
    },
}));
