import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import MaterialTable from '@material-table/core';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import dayjs from 'dayjs';
import Recycle from "../../Recycle";

const AgendaEvents = ({config, props}) => {
    const history = useHistory();
    const classes = useStyles();
    const [selectedEventId, setSelectedEventId] = useState(null);

    const columns = [
        {
            title: "Due Date",
            render: rowData => <div>{dayjs(rowData.event_date).format('DD MMMM YYYY')}</div>
        },
        {
            field: "name",
            title: "Event"
        },
        {
            field: "description",
            title: "Description"
        },
        {
            render: rowData => <Radio value={rowData.event_id} checked={selectedEventId === rowData.event_id} onChange={handleChange} />
        }

    ];

    const handleChange = (event) => {
        setSelectedEventId(event.target.value);
    }

    const handleNone = () => {
        Recycle.rpc({
            model: "AgendaManager",
            method: "complete_agenda_event",
            args: ["none", props.consult_id]
        }).then(res => {
            history.push("/dashboard");
        });
    }

    const handleProceed = () => {
        Recycle.rpc({
            model: "AgendaManager",
            method: "complete_agenda_event",
            args: [selectedEventId, props.consult_id]
        }).then(res => {
            history.push("/dashboard");
        });
    }

    return (
        <div className={classes.container}>
            <MaterialTable
                title="Please select the relevant scheduled consult for the employee"
                columns={columns}
                data={props.agenda_events}
                options={{search: false}}
            />
            <div className={classes.buttonsContainer}>
                <Button variant="outlined" color="primary" onClick={handleNone}>none of the above</Button>
                <Button variant="contained" color="primary" onClick={handleProceed} disabled={!selectedEventId}>proceed</Button>
            </div>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    container: {
        display: "grid",
        gridAutoFlow: "row",
        gap: theme.spacing(3)
    },
    buttonsContainer: {
        justifySelf: "end",
        display: "grid",
        gridAutoFlow: "column",
        gap: theme.spacing(3)
    }
}));

export default AgendaEvents;