import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useHistory} from 'react-router-dom';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionActions from '@material-ui/core/AccordionActions';
import Icon from '@material-ui/core/Icon';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Recycle from '../Recycle';
import DocumentViewerDialog from "./DocumentViewer/DocumentViewerDialog";


const ProcedureDetailsAccordion = ({procedure, consult_id, editable = true}) => {
    const classes = useStyles();
    const history = useHistory();
    const [procedureValues, setProcedureValues] = React.useState({});
    const [openPdfViewer, setOpenPdfViewer] = React.useState(false);
    const [pdfB64String, setPdfB64String] = React.useState('');
    const [documentTitle, setDocumentTitle] = React.useState('');


    useEffect(() => {
        getProcedureDetails();
    }, []);

    const getProcedureDetails = () => {
        Recycle.rpc({
            model: "ProcedureController",
            method: "get_procedure_details",
            args: [consult_id, procedure.procedure_id]
        }).then(res => {
            setProcedureValues(res.values);
        }).catch(err => {

        });
    }

    const handleEdit = () => {
        history.push(`/consults/${consult_id}/procedure/${procedure.procedure_id}`);
    }

    const handleOpenPDF = () => {
        setOpenPdfViewer(true)

    };

    const setClosePdfViewer = () => {
        setOpenPdfViewer(false)

    }
    const handleOpenDocument = (procedure_field, key) => {
        Recycle.rpc({
            model: "DocumentController",
            method: "get_procedure_document_as_base64",
            args: ["", key]
        }).then((res) => {
            setPdfB64String(res.document_b64)
            handleOpenPDF()
            setDocumentTitle(procedure_field)
        }).catch((err) => {

        })
    }


    const getValue = (value, field = null) => {
        if (Array.isArray(value)) {
            return (
                value.map(field => {
                    return (<Chip key={field} label={field} variant="outlined"/>)
                })
            )
        }
        if (typeof value === 'string') {
            if (value.startsWith('Consult_Documents/')) {
                return (<Button style={{height: '18px', padding: '1px'}} size={'small'} color={"primary"}
                                variant={"contained"} onClick={() => {
                    handleOpenDocument(field, value)
                }}> Open Document </Button>)
            }
        }
        return value
    }

    return (
        <Accordion square={true}>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                <div className={classes.accordionSummary}>
                    <div className={classes.accordionSummaryLeft}>
                        <Icon className={classes.accordionSummaryIcon}>{procedure.icon}</Icon>
                        <div className={classes.accordionSummaryText}>{procedure.procedure_name}</div>
                    </div>
                    <div className={classes.accordionSummaryStatus}>{procedure.procedure_status}</div>
                </div>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
                {Object.keys(procedureValues).map(key => (
                    <React.Fragment key={key}>
                        <div>{key.replace(/_/g, ' ')}</div>
                        <div style={{marginLeft: '7px'}}>{getValue(procedureValues[key], key.replace(/_/g, ' '))}</div>
                    </React.Fragment>
                ))}
            </AccordionDetails>
            {editable === true &&
            <AccordionActions>
                <Button color="primary" variant="contained" onClick={handleEdit}>Edit</Button>
            </AccordionActions>}
            <DocumentViewerDialog title={documentTitle} open={openPdfViewer} data={pdfB64String}
                                  setClose={setClosePdfViewer}/>
        </Accordion>
    )
}

const useStyles = makeStyles((theme) => ({
    heading: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2)
    },
    accordionSummary: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: "100%"
    },
    accordionSummaryLeft: {
        display: 'flex',
        alignItems: 'center'
    },
    accordionSummaryIcon: {
        marginRight: theme.spacing(3),
        color: theme.palette.success.main
    },
    accordionSummaryStatus: {
        color: theme.palette.success.main,
        textTransform: 'capitalize'
    },
    accordionSummaryText: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        fontSize: '12px'
    },
    accordionDetails: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        textTransform: 'capitalize',
        rowGap: `${theme.spacing(2)}px`
    }
}));

export default ProcedureDetailsAccordion;