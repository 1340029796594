import React, {useEffect} from 'react';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from 'react-select';
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import DialogContent from "@material-ui/core/DialogContent";
import {Button} from "@material-ui/core";
import Recycle from "../../Recycle";
import {useSnackbar} from "notistack";
import {useHistory} from "react-router-dom";


const OverrideStatus = ({config, props}) => {
    const [updatedCaseStatus, setUpdatedCaseStatus] = React.useState({value: '', label: ''});
    const [notes, setNotes] = React.useState('');
    const [statusOptions, setStatusOptions] = React.useState([]);
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const history = useHistory();

    useEffect(() => {

        if (props.override_options !== undefined && props.override_options.length > 0) {
            const options = props.override_options[0].map((option) => {
                return {value: option, label: option}
            })
            setStatusOptions(options)
            setUpdatedCaseStatus(options[0])
        }
    }, [props.override_options]);


    const handleChange = (event) => {
        setUpdatedCaseStatus(event)

    };

    const handleUpdateCase = () => {
        Recycle.rpc({
            model: "ConsultController",
            method: "override_consult_result",
            args: [props.consult_id, updatedCaseStatus.value, notes]
        }).then(res => {
            history.push(`/consults/${props.consult_id}/signoffcomplete`);
            enqueueSnackbar("Consult Result Successfully Overridden", {variant: "success"});
        }).catch((err) => {
                enqueueSnackbar("Failed to set Override Result", {variant: "error"});
            }
        );
    }
    return (
        <div>
            <Paper className={classes.root}>
                <InputLabel  shrink={true}>Select Case Management Update</InputLabel>
                <FormControl fullWidth>

                    <Select
                        styles={customStyles}
                        value={updatedCaseStatus}
                        isDisabled={false}
                        isLoading={false}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={false}
                        name="case_status"
                        options={statusOptions}
                        onChange={handleChange}

                    />
                    <TextField
                        onChange={(e) => setNotes(e.target.value)}
                        margin="normal"
                        variant="outlined"
                        id="status-notes"
                        label="Status Notes"
                        multiline={true}
                        fullWidth
                        rows={4}
                    />

                    <Button color="primary" disabled={updatedCaseStatus.value === '' || notes === ''}
                            variant="contained"
                            onClick={handleUpdateCase}> Update Case</Button>
                </FormControl>
            </Paper>

        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 20
    },
}));
const customStyles = {
    menu: base => ({
        ...base,
        zIndex: 100
    }),
    control: (provided, state) => {
        return {
            ...provided,
            background: 'none',
            border: 'none',
            borderColor: 'none',
            boxShadow: 'none',
            borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
            borderRadius: 0
        }
    }
}
export default OverrideStatus;
