import React from 'react';
import Field from './Field';
import { Form, FormSpy } from 'react-final-form';
// import {useFormState} from 'react-final-form';
import { evaluatePolishExpression } from '../../Recycle';
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid } from '@material-ui/core';

class FlattenedFormFields {
    constructor(config) {
        this.config = config;
        this.values = this.getFormFields();
    }

    getFormFields() {
        let values = [];
        for (let group of this.config.fields.groups) {
            values = values.concat(group.fields);
        }
        return values;
    }

    defaults() {
        let default_values = {};
        for (let field of this.values) {
            if (field.hasOwnProperty("default") && field["default"] && field["default"] !== "None") {
                default_values[field["id"]] = field["default"];
            }
        }
        return default_values
    }
}

const DynamicForm = ({ config, props, children, onSubmit, actions = [], debug = false, extraValues = {} }) => {

    const classes = useStyles();
    const [defaultValues, setDefaultValues] = React.useState([]);
    const [formConfig, setformConfig] = React.useState([]);

    React.useEffect(() => {
        const flattened_fields = new FlattenedFormFields(config);
        setDefaultValues(flattened_fields.defaults());
        setformConfig(flattened_fields.values)
    }, [config])


    let inheritedConfigValues = config.defaultValues ? config.defaultValues : {}
    let defaults = { ...defaultValues, ...inheritedConfigValues };

    const validate = values => {
        const errors = {};
        formConfig.forEach(function (field) {
            if (Array.isArray(field.validation) && field.validation.length > 0) {
                if (Array.isArray(field.render_condition) && field.render_condition.length > 0) {
                    if (evaluatePolishExpression(field.render_condition, values) && !evaluatePolishExpression(field.validation, values)) {
                        errors[field.id] = "Invalid";
                    }
                } else {
                    if (!evaluatePolishExpression(field.validation, values)) {
                        errors[field.id] = "Invalid";
                    }
                }
            }
        });
        return errors;
    };

    const warning = (warnings, values) => {
        if (!warnings) return null;
        for (let warning of warnings) {
            if (evaluatePolishExpression(warning.validation, values)) {
                return warning.message;
            }
        }
        return null;
    };

    const setDefault = (values, id) => {
        if (values !== undefined) {
            if (id in values) {
                return values[id]
            }
            if (defaults !== undefined && id in defaults && defaults[id] !== "None") {
                values[id] = defaults[id];
                return defaults[id]
            }
        }
        return null
    }

    const internalOnSubmit = (values) => {
        return onSubmit(values);
    }


    return (<Form
        onSubmit={internalOnSubmit}
        subscription={{ submitting: true, pristine: true }}
        // subscription={{values: true}}
        initialValues={{ ...defaultValues, ...inheritedConfigValues }}
        validate={validate}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
                {
                    config.fields.groups.map(group => {
                        return (
                            <div key={group.id}>
                                {group.name ? <div key={`${group.id}${group.name}`} className={classes.heading}>{group.name}</div> : null}
                                {
                                    group.fields.map(field => {
                                        if (Array.isArray(field.render_condition) && field.render_condition.length > 0) {
                                            return (
                                                <FormSpy key={`${field.id}${field.name}form`}
                                                    subscription={{ values: true }}>
                                                    {({ values }) => (
                                                        evaluatePolishExpression(field.render_condition, { ...values, ...extraValues }) ?
                                                            <Field key={`${field.id}${field.name}`}
                                                                warning={warning(field.warnings, values)}
                                                                initial={setDefault(values, field.id)} {...field} /> : null
                                                    )}
                                                </FormSpy>
                                            )
                                        }
                                        return (
                                            <Field key={`${field.id}${field.name}`}
                                                warning={warning(field.warnings, values)}
                                                initial={setDefault(values, field.id)} {...field} />
                                        )
                                    })
                                }
                                <br key={`${group.id}br`} /><br />
                            </div>
                        )
                    })
                }
                {
                    React.Children.map(children, child => (child))
                }
                {debug && <DebugForm />}
                <Grid container
                    direction="row"
                    justify="flex-end"
                    alignItems="flex-end"
                    style={{ marginTop: 16, padding: "15px", gridGap: 12 }}>
                    {
                        actions.length !== 0 ? null :
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={submitting}
                            >
                                Submit
                                </Button>
                    }
                    {
                        actions.map(button => {
                            if (React.isValidElement(button)) {
                                return button;
                            }
                            if (button instanceof Function) {
                                return button({ submitting, getValues: () => form.getState().values })
                            }
                        })
                    }
                </Grid>
            </form>
        )}
    />
    )
}

const DebugForm = ({ values }) => (
    <FormSpy subscription={{ values: true }}>
        {({ values }) => (
            <pre>
                {JSON.stringify(values || {}, 0, 2)}
            </pre>
        )}
    </FormSpy>
)

const useStyles = makeStyles((theme) => ({
    heading: {
        color: theme.palette.primary.main,
        fontSize: '16px',
        fontWeight: 'bold',
        marginBottom: theme.spacing(3)
    }
}));

export default DynamicForm;