import React from 'react';
import {Field} from 'react-final-form';
import FieldLayout from './FieldLayout'
import Select from 'react-select';
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
//
// const options = [{"label": "test_1", value: 1},{"label": "test_2", value: 2}]
// const ControlledInput = () => {
//
//     const [value, setValue] = React.useState(null);
//     const [selectValue, setSelectValue] = React.useState(null);
//
//
//     React.useEffect(() => {
//         const found = options.find(opt => opt.value === value);
//
//         if (found !== undefined) {
//             setSelectValue(found);
//         }
//     }, [value])
//
//     return <Select
//         value={selectValue}
//         onChange={v => setValue((v.value))}
//         options={options}
//     />
// }
// export default ControlledInput


const ReactSelectAdapter = ({input, ...rest}) => {

    const classes = useStyles();

    const {onChange, ...restInput} = input;
    const [selectedOption, setSelectedOption] = React.useState(null);

    React.useEffect(() => {

        const optionFromID = rest.options.find(o => o.value === restInput.value)

        if (optionFromID !== undefined) {
            setSelectedOption(optionFromID);
        } else {
            setSelectedOption(null);
        }
    }, [restInput.value, rest.options])

    const internalOnChange = (e) => {
        onChange(e?.value !== undefined ? e?.value : null)
    }

    return (
        <>
            <InputLabel shrink={true}>{rest.label}</InputLabel>
            <Select
                {...restInput}
                {...rest}
                styles={customStyles}
                isClearable={true}
                isSearchable={true}
                onChange={internalOnChange}
                value={selectedOption}
                isRequired
            />
            {
                // Hack - Select does not support required
                <input
                    tabIndex={-1}
                    autoComplete="off"
                    style={{opacity: 0, height: 0}}
                    value={selectedOption?.value !== undefined ? selectedOption?.value : ''}
                    required={true}
                    // readOnly
                    onChange={() => {}}
                />
            }
        </>);
}

const DropdownField = ({id, name, optional, options, unit, description, warning}) => {
    let label = name;
    let adornment = unit ? " ".concat("(", unit, ")") : "";
    let mapped_options = options.map(({id, name}) => ({label: name.concat(adornment), value: id}));

    return (
        <FieldLayout description={description} warning={warning}>
            <Field name={id}
                   label={label}
                   optional={optional}
                   options={mapped_options}
                   component={ReactSelectAdapter}
                   adornment={adornment}>
            </Field>
        </FieldLayout>
    )
}

const useStyles = makeStyles((theme) => {
    return {
        label: {
            color: theme.palette.grey[600],
            fontSize: '0.9rem'
        },
    }
});

const customStyles = {
    control: (provided, state) => {
        return {
            ...provided,
            background: 'none',
            border: 'none',
            borderColor: 'none',
            boxShadow: 'none',
            borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
            borderRadius: 0,
            zIndex: 99
        }
    },
    menu: base => ({
        ...base,
        zIndex: 100
      })
}

export default DropdownField;