import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import {makeStyles} from '@material-ui/core/styles';
import Divider from "./Divider";


const ConsultResultDetail = ({rule: {rule_name, result_text, notes}}) => (
    <div>
        <Typography variant="h6">Result of {rule_name}</Typography>
        <Typography variant="body1" color="textSecondary">{result_text}</Typography>
        { notes && notes.length >= 1 && <Typography variant="body1">Notes:</Typography> }
        { notes && notes.length >= 1 && <ul>{notes.map((note, idx) => <li key={idx}><p>{note}</p></li>)}</ul> }
        <Divider/>

    </div>
)

const ConsultResult = ({props: {rules_result, rules}}) => {
    const classes = useStyles();

    if (!rules_result || rules_result.length === 0) return null;

    return (
        <Paper className={classes.root}>
            {
                rules_result.map(rule => <ConsultResultDetail key={rule.rule_id} rule={rule}/>)
            }
        </Paper>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 20
    },
}));

export default ConsultResult;