import React, {useState} from 'react';
import {Document, Page, pdfjs} from "react-pdf";
import {makeStyles, useTheme} from '@material-ui/core/styles';
import FileSaver from 'file-saver';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import {Fab} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    document: {
        display: 'absolute',
        marginLeft: 'auto',
        marginRight: 'auto',
        bottomMargin: '1px',
        height: '100%',
        overflow: 'auto'
    },
    page: {
        border: '1px solid #ccc',
        margin: 5,
        marginLeft: 20,
        marginRight: 20
    },
    documentContainer: {
        width: '100%',
        height: '100%',
        minHeight: '100%',
        overflow: 'hidden',
        display: 'grid',
        gridTemplateRows: '1fr auto'
    }

}));

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const PdfViewer = (props) => {
    const theme = useTheme();
    const classes = useStyles();

    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({numPages}) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    return (
        <>
            <div className={classes.documentContainer}>

                <div className={classes.document}>
                    <Document
                        file={`data:application/pdf;base64,${props.data}`}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        <Page
                            className={classes.page}
                            scale={props.scale}
                            pageNumber={pageNumber}
                        />
                    </Document>
                </div>
                <MobileStepper
                    steps={numPages}
                    position="static"
                    variant="text"
                    activeStep={pageNumber - 1}
                    nextButton={
                        <Button size="small" onClick={nextPage} disabled={pageNumber === numPages}>
                            Next
                            {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={previousPage} disabled={pageNumber === 1}>
                            {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                            Back
                        </Button>
                    }/>
            </div>

            <Fab style={{position: 'fixed', right: '30px', bottom: '60px'}} onClick={() => {
                var blob = base64toBlob(props.data, 'application/pdf');
                FileSaver.saveAs(blob, props.download_filename)
            }} color="primary" aria-label="download">
                <CloudDownloadIcon/>
            </Fab>


        </>
    );
};

export default PdfViewer;


const base64toBlob = function (b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, {type: contentType});
    return blob;
};