import React, {useContext} from 'react';
import {Fab} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import {makeStyles} from '@material-ui/core/styles';
import {useHistory} from "react-router-dom";
import {AppConfigContext} from "../../App";


const useStyles = makeStyles((theme) => ({
    margin: {
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 20,
        left: 'auto',
        position: 'fixed',
        zIndex: 99999
    },
    addIcon: {
        marginRight: theme.spacing(1),
        zIndex: 99999

    },
}));

const check_if_fixed_site = (site_code) => {
    const split_site = site_code.split("/");
    const site = split_site[split_site.length - 2]
    return site.length >= 3 && site.substring(0, 3) === "FNS";
};

const ManualBookingButton = props => {
    const history = useHistory();
    const classes = useStyles();
    const appConfig = useContext(AppConfigContext);
    const is_fixed_site = appConfig.dsp_details.is_fixed_site;

    return (
        is_fixed_site ?
            <div>
                <Fab variant="extended" color="primary" aria-label="add" className={classes.margin}
                     onClick={() => history.push('manual_booking/create')}>
                    <AddIcon className={classes.addIcon}/>
                    Add Booking
                </Fab>
            </div> : null
    );
};

export default ManualBookingButton;
