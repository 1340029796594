import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const PatientDetailsCell = ({ consult }) => {
    const classes = useStyles();
    return (
        <div>
            <div className={classes.fullName}>{consult.patient.first_name} {consult.patient.last_name}</div>
            <div className={classes.service}>Service: {consult.tariff_description}</div>
        </div>
    )
};

const useStyles = makeStyles(theme => ({
    fullName: {
        fontSize: '14px',
        fontWeight: 600,
    },
    service: {
        fontSize: '11px',
        color: '#666666',
        lineHeight: '15px',
        textTransform: 'capitalize'
    }
}));

export default PatientDetailsCell;