import React from 'react';

import axios from "axios";
import {FilePond, registerPlugin} from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';


import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import Recycle from '../../Recycle';

registerPlugin(FilePondPluginImagePreview);
registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginFileValidateSize);
const FileUploader = (props) => {

    const handleUploadSuccess = (bucket_key) => {
        console.log(bucket_key)
    };

    return (
        <FilePond
            key={'file_upload'}
            maxFileSize="5MB"
            server={{
                revert: (uniqueFileId, load, error) => {
                    handleUploadSuccess("");
                    load();
                },
                process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
                    // send a POST request to retrieve presigned url for s3
                    const CancelToken = axios.CancelToken;
                    const source = CancelToken.source();
                    const contentType = file.type;
                    const fileType = file.type.split('/', 2);
                    const media_type = fileType[0];
                    const media_extension = fileType[1];
                    Recycle.rpc({
                        model: "PatientController",
                        method: "create_presigned_post_url",
                        args: [`${props.patient_identifier}/${props.booking_id}`, contentType, media_extension]
                    }).then((res) => {
                        console.log(res)
                        const responseData = res;
                        const presignedUrlData = responseData[0];
                        const presignedUrl = presignedUrlData.url;
                        const fields = presignedUrlData["fields"];
                        const headers = {
                            'Content-Type': '"multipart/form-data',
                        };
                        let formData = new FormData();
                        for (let key in fields) {
                            if (fields.hasOwnProperty(key)) {
                                formData.append(key, fields[key]);
                            }
                        }
                        formData.set('Content-Type', contentType);
                        formData.append('file', file);
                        axios.post(presignedUrl, formData, {
                            headers: headers,
                            timeout: 15000,
                            cancelToken: source.token,
                            onUploadProgress: progressEvent => {
                                progress(progressEvent.lengthComputable, progressEvent.loaded, progressEvent.total)
                            }
                        }).then((responseFileUpload) => {
                            load(fields["key"]);
                            handleUploadSuccess(fields["key"]);
                        }).catch(errorUpload => {
                            if (axios.isCancel(errorUpload)) {
                                console.log('Request cancelled', errorUpload.message);
                            } else {
                                error()
                                // handle error
                            }

                        })
                    })
                        .catch((e) => {
                                if (axios.isCancel(e)) {
                                    console.log('Request canceled', e.message);
                                } else {
                                    error()
                                    // handle error
                                }

                            }
                        );
                    // Should expose an abort method so the request can be cancelled
                    return {
                        abort: () => {
                            console.log("aborting upload");
                            // cancel the request (the message parameter is optional)
                            source.cancel('Upload cancelled by the user.');

                            // Let FilePond know the request has been cancelled
                            abort();
                        }
                    };

                }
            }}
            allowMultiple={false}
            credits={false}
            acceptedFileTypes={['image/*', 'application/pdf']}
            labelIdle='<span class="filepond--label-action">Upload Terms and Conditions</span>'
            // ref={ref => this.pond = ref}
        />
    );
};

export default FileUploader;
