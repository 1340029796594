import React, {useEffect} from 'react';
import {useForm} from 'react-final-form';
import TextboxField from './TextField';
import MultiLineTextboxField from './MultiLineTextField'
import RadioField from './RadioField';
import DateField from './DateField';
import DateTimeField from './DateTimeField'
import DropdownField from './DropdownFieldReactSelect'
import NumericField from "./NumericField";
import NumericInputField from "./NumericInputField";
import CalculatedNumericField from "./CalculatedNumericField";
import ScoringDropdownField from "./ScoringDropdownFieldReactSelect";
import ToggleButtonField from "./ToggleButtonFieldReact"
import MultiSelectField from "./MultiSelectField"
import UploadField from "./UploadFileField";
import UploadUniversalField from "./UploadUniversalFileField";

const InputField = (props) => {
    const form = useForm();

    useEffect(() => {
        return () => {
            form.change(props.id, undefined);
            form.resetFieldState(props.id);
        }
    }, [form]);

    switch (props.type) {
        case 'text':
            return <TextboxField {...props} />
        case 'multi_line_text':
            return <MultiLineTextboxField {...props} />
        case 'multi_select':
            return <MultiSelectField {...props} />
        case 'toggle_button':
            return <ToggleButtonField {...props}/>
        case 'numeric':
            return <NumericInputField {...props} integer={false}/>
        case 'integer':
            return <NumericField {...props} integer={true}/>
        case 'radio':
            return <RadioField {...props} />
        case 'drop_down':
            return <DropdownField {...props} />
        case 'drop_down_integer_value':
            return <DropdownField {...props} />
        case 'scoring_drop_down':
            return <ScoringDropdownField {...props} />
        case 'date':
            return <DateField {...props} />
        case 'datetime':
            return <DateTimeField {...props} />
        case 'calculated_numeric':
            return <CalculatedNumericField {...props} />
        case 'upload':
            return <UploadField {...props} />
        case 'universal_upload':
            return <UploadUniversalField {...props} />
        default:
            return null;
    }
}

export default InputField;