import {Grid, Typography} from "@material-ui/core";
import React from "react";

const FieldLayout = ({children, description, warning}) => {
    return (
        <Grid
            wrap="nowrap"
            spacing={5}
            container
            direction="row"
            justify="space-between"
            alignItems="center"
        >
            <Grid item xs={8}>
                {children}
            </Grid>
            <Grid item xs={5}>
                <label style={{color: "grey", fontSize: "12px"}}>{description}</label>
            </Grid>
            <Grid item xs={2}>
                <label style={{color: "red", fontSize: "16px"}}>{warning}</label>
            </Grid>


        </Grid>
    )
}

export default FieldLayout;