import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { consultStatusColorMapping } from '../const';

const StatusCell = ({ status }) => {
    const classes = useStyles({ status });

    return (
        <div className={classes.status}>{status.replace(/_/g, ' ')}</div>
    )
}

const useStyles = makeStyles((theme) => ({
    status: (props) => {
        const color = consultStatusColorMapping(theme)[props.status].main;

        return {
            fontSize: '14px',
            fontWeight: 600,
            color: color,
            textTransform: 'capitalize'
        }

    }
}));

export default StatusCell;