import React from 'react';
import { makeStyles } from '@material-ui/core/styles'

const ServiceCell = ({service}) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {service}
        </div>
    )
}

const useStyles = makeStyles({
    root: {
        textTransform: 'capitalize',
        fontSize: '12px'
    }
});

export default ServiceCell;