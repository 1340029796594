import React, {useImperativeHandle, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Select from '@material-ui/core/Select';
import Slide from '@material-ui/core/Slide';
import MaterialTable, {MTableBody, MTableHeader} from "@material-table/core";
import {DialogActions, DialogContent, DialogTitle, MenuItem} from "@material-ui/core";
import dayjs from "dayjs";
import Button from "@material-ui/core/Button";
import {v4 as uuidv4} from 'uuid';
import AddNewBatchDialog from "./AddNewBatchDialog";

const useStyles = makeStyles((theme) => ({
    container: {},
    appBar: {
        position: 'relative',
    },

    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

let originalGetBatchExpMap = {}
let numberOfBatches = 0
const getBatchExpMap = (batchItems) => {
    if (batchItems === undefined) {
        return {}
    }
    let retMap = {}
    Object.keys(batchItems).forEach((item) => {
        retMap[batchItems[item].batch] = {expiry: batchItems[item].expiry, id: batchItems[item].id}
        numberOfBatches = numberOfBatches + 1;
    })
    originalGetBatchExpMap = retMap;
    return retMap

}

const CreatableBatchDetailsDialog = React.forwardRef((props, ref) => {
    const classes = useStyles();
    const tableRef = React.createRef();
    const [batchExpMap, setBatchExpMap] = useState({})
    const [isEditing, setIsEditing] = useState(false)
    const [actions, setActions] = useState([])
    const [data, setData] = useState(
        []
    );
    const [openCreateBatchDialog, setOpenCreateBatchDialog] = useState(false)
    const handleClose = () => {
        props.setClose()
    };

    React.useEffect(() => {
        if (props.stockItem !== {}) {
            setBatchExpMap(getBatchExpMap(props.stockItem.batch_items))
        }
    }, [props.stockItem])

    const startEditExistingDetails = () => {
        if (props.stockItem !== {} && props.stockItem.batch_stock_moves_captured) {
            setBatchExpMap(props.stockItem['original_get_batch_exp_map'])
            originalGetBatchExpMap = props.stockItem['original_get_batch_exp_map']

            const existingBatchData = Object.keys(props.stockItem.batch_items).map((id) => {
                return {
                    batch: props.stockItem.batch_items[id].batch,
                    expiry_date: props.stockItem.batch_items[id].expiry,
                    item: props.stockItem.name,
                    quantity: props.stockItem.batch_items[id].quantity,
                    uom: props.stockItem.uom.name
                }

            });
            setData(existingBatchData);
            setBatchExpMap(Object.keys(batchExpMap)
                .filter(key => !Object.keys(props.stockItem.batch_items).includes(key))
                .reduce((result, current) => {
                    result[current] = batchExpMap[current];
                    return result;
                }, {}));
        }
    }

    useImperativeHandle(ref, () => ({
        startEditExistingDetails: startEditExistingDetails
    }));


    const handleCloseBatchDialog = () => {
        setOpenCreateBatchDialog(false)
    };

    function getNested(obj, ...args) {
        return args.reduce((obj, level) => obj && obj[level], obj)
    }

    const checkIfValuesValid = (value, nested_field = null) => {
        if (nested_field === null) {
            return value ? value : ""
        } else {
            return getNested(value, nested_field) ? value[nested_field] : ""
        }

    }
    const columns = [
        {
            field: 'item',
            title: 'Item',
            editable: 'never',
            initialEditValue: checkIfValuesValid(props.stockItem.name)
        },
        {
            field: 'quantity',
            title: 'Quantity',
            type: 'numeric',
            validate: rowData => rowData.quantity >= 0
        },
        {
            field: 'uom',
            title: 'Unit of Measure',
            editable: 'never',
            initialEditValue: checkIfValuesValid(props.stockItem.uom, "name")
        },
        {
            field: 'batch',


            title: 'Batch No.',
            validate: (rowData) =>
                rowData.batch === undefined
                    ? { isValid: false, helperText: 'Batch cannot be empty' }
                    : rowData.batch === ''
                    ? { isValid: false, helperText: 'Batch cannot be empty' }
                    : true
        },
        {
            field: 'expiry_date',
            title: 'Expiry Date',
            type: 'date',
            validate: (rowData) =>
                rowData.expiry_date === undefined
                    ? { isValid: false, helperText: 'Expiry Date cannot be empty' }
                    : rowData.expiry_date === ''
                    ? { isValid: false, helperText: 'Expiry Date cannot be empty' }
                    : true

        }
    ]

    const DropDown = ({value, onChange}) => (
        <Select onChange={onChange} value={value || ""}>
            {Object.keys(batchExpMap).map((value) => <MenuItem key={value} value={value}>{value}</MenuItem>)}

        </Select>
    );

    const createNewBatch = (values) => {
        const batchDetails = {expiry: dayjs(values.expiry_date).toISOString(), id: uuidv4()}
        setBatchExpMap(prevState => ({
            ...prevState,
            [values.batch_number]: batchDetails,
        }))
        originalGetBatchExpMap[values.batch_number] = batchDetails;
    }

    const submitBatchDetails = () => {
        let output = {};
        let quantity_sum = 0;
        data.forEach((value => {
            const id = uuidv4()
            output[id] = {
                id: id,
                batch: value.batch,
                expiry: dayjs(value.expiry_date).toISOString(),
                quantity: value.quantity
            }
            quantity_sum = quantity_sum + value.quantity;
        }))
        props.setClose();
        props.updateBatchDetails(output, props.stockItem[props.item_identifier], quantity_sum)
        setData([])
    }

    return (

        <Dialog fullWidth maxWidth="lg" style={{padding: '20px'}} className={classes.dialog} open={props.open}
                onClose={handleClose}
                TransitionComponent={Transition}>
            <>
                <div className={classes.container}>
                    <MaterialTable
                        className={classes.table}
                        localization={{
                            body: {
                                emptyDataSourceMessage: null
                            },
                        }}
                        tableRef={tableRef}
                        data={data}
                        columns={columns}
                        title={<div>Details for batch items</div>}
                        editable={{
                            onRowAdd: newData =>
                                new Promise((resolve, reject) => {
                                    // newData['expiry_date'] = batchExpMap[newData.batch].expiry
                                    setTimeout(() => {
                                        setIsEditing(false)
                                        setData([...data, newData]);
                                        const {[newData.batch]: tmp, ...rest} = batchExpMap;
                                        setBatchExpMap(rest);
                                        resolve();
                                    }, 10)
                                })
                            ,
                            onRowUpdate: (newData, oldData) =>
                                new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                        const dataUpdate = [...data];
                                        const index = oldData.tableData.id;
                                        dataUpdate[index] = newData;
                                        setData([...dataUpdate]);
                                        setBatchExpMap({[oldData.batch]: originalGetBatchExpMap[oldData.batch], ...batchExpMap});

                                        resolve();
                                    }, 10)
                                }),
                            onRowDelete: oldData =>
                                new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                        const dataDelete = [...data];
                                        const index = oldData.tableData.id;
                                        dataDelete.splice(index, 1);
                                        setData([...dataDelete]);
                                        setBatchExpMap({[oldData.batch]: originalGetBatchExpMap[oldData.batch], ...batchExpMap});
                                        resolve();
                                    }, 1000)
                                }),
                        }}
                        options={{
                            headerStyle: {
                                color: '#999999',
                                fontSize: '11px',
                                fontWeight: 600,
                                lineHeight: '15px'
                            },
                            actionsColumnIndex: -1,
                            paging: false,
                            filtering: false,
                            search: false,
                            searchFieldAlignment: "left",
                        }}
                        pageSize={15}
                        actions={actions}

                    />

                </div>
                <div style={{
                    padding: '10px',
                    display: 'grid',
                    gridTemplateColumns: 'auto auto auto auto',
                    alignContent: 'space-around'
                }}>

                    <Button style={{width: '200px', padding: '3px', justifySelf: 'end'}}
                            onClick={() => {
                                props.setClose();
                                setData([]);

                            }}
                            color={"default"}
                            variant={'contained'}>Cancel</Button>
                    <Button style={{width: '200px', padding: '3px', justifySelf: 'end'}}
                            onClick={() => {
                                const materialTable = tableRef.current;
                                materialTable.dataManager.changeRowEditing();
                                materialTable.setState({
                                    ...materialTable.dataManager.getRenderState(),
                                    showAddRow: true,
                                });
                                setIsEditing(true)
                            }}
                            color={"primary"}
                            disabled={props.type==='grv'? false:Object.keys(batchExpMap).length === 0}
                            variant={'contained'}>Add Batch</Button>
                    <Button style={{width: '150px', padding: '3px', justifySelf: 'end'}} onClick={submitBatchDetails}
                            color={"primary"}
                            disabled={props.type === 'stock_take' ? data.length < numberOfBatches : isEditing || data.length === 0}
                            variant={'contained'}>Done</Button>
                </div>

                <AddNewBatchDialog open={openCreateBatchDialog} handleClose={handleCloseBatchDialog}
                                   createNewBatch={createNewBatch}/>

            </>
        </Dialog>
    );
});

export default CreatableBatchDetailsDialog;

