import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';
import DynamicForm from "./DynamicForm";
import Recycle from "../Recycle";
import {useHistory} from "react-router-dom";
import {AppConfigContext} from "../App";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {Button} from "@material-ui/core";
import {useSnackbar} from "notistack";

const ManualBookingForm = props => {
    const [patient, setPatient] = useState(null);
    const [showPatientDetails, setShowPatientDetails] = useState(false);
    const [initialFormValues, setInitialFormValues] = useState({});
    const [manualBookingDetails, setManualBookingDetails] = useState(manual_booking_details);
    const history = useHistory();
    const classes = useStyles();
    const appConfig = useContext(AppConfigContext);
    const {enqueueSnackbar} = useSnackbar();

    const getServices = () => {
        const client_id = appConfig.user?.dsp.split("/")[2];
        const args = [client_id];
        Recycle.rpc({model: 'ServiceController', method: 'get_walk_in_services', args}).then(res => {
            const options = res.map(service => ({id: service.tariff_code, name: service.service_description}));
            const field_length = manual_booking_details.groups[0].fields.length
            manual_booking_details.groups[0].fields[field_length - 1].options = options;
            setManualBookingDetails(manual_booking_details)
        });
    }

    React.useEffect(() => {
        getServices();
    }, [])

    const handleSearch = (values) => {
        const args = [values.identifier_type, values.identifier];
        Recycle.rpc({model: 'PatientController', method: 'search_patient', args}).then(res => {
            setInitialFormValues(res)
            setPatient(res);
            setShowPatientDetails(true);
        }).catch(err => {
            if (err.response.status === 404) {
                setInitialFormValues(values);
                setShowPatientDetails(true);
            }
            ;
        })
    }
    const renderSearchForm = () => (
        <DynamicForm onSubmit={handleSearch}
                     config={{fields: searchForm, defaultValues: {}}}
                     actions={[
                         ({submitting}) => <Button
                             key="cancel"
                             disabled={submitting}
                             onClick={() => history.push(`/dashboard`)}
                             variant="outlined">
                             Cancel
                         </Button>,
                         ({submitting}) => (<Button
                             key="next"
                             variant="contained"
                             color="primary"
                             type="submit"
                             disabled={submitting}
                         >
                             Next
                         </Button>)
                     ]}
        />)

    const renderPatientForm = () => (
        <DynamicForm onSubmit={handleConfirmBooking}
                     config={{fields: manualBookingDetails, defaultValues: initialFormValues}}
                     actions={[
                         ({submitting}) => <Button
                             key="cancel"
                             disabled={submitting}
                             onClick={() => history.push(`/dashboard`)}
                             variant="outlined">
                             Cancel
                         </Button>,
                         ({submitting}) => (<Button
                             key="save"
                             variant="contained"
                             color="primary"
                             type="submit"
                             disabled={submitting}
                         >
                             Create
                         </Button>)
                     ]}
        />)

    const handleConfirmBooking = (values) => {
        const args = [appConfig.user.dsp, values, values.service];
        Recycle.rpc({model: 'BookingController', method: 'create_manual_booking', args}).then(manual_booking_res => {
            enqueueSnackbar("Booking Created", {variant: "success"});
            appConfig.setState({...appConfig, currentBookingID: manual_booking_res.booking_id});
            if (!patient) {
                Recycle.rpc({
                    model: 'PatientController',
                    method: 'save_patient',
                    args: [values]
                }).then(save_patient_res => {
                    history.push(`/booking/${manual_booking_res.booking_id}/consent/${save_patient_res['patient']['patient_identifier']}`);

                }).catch(err => {
                    console.log(err.response.data.message);
                });
            } else {
                history.push(`/booking/${manual_booking_res.booking_id}/consent/${patient['patient_identifier']}`);
                // if (!('accepted_terms_and_conditions' in patient) || patient['accepted_terms_and_conditions'] === false) {
                //     history.push(`/booking/${manual_booking_res.booking_id}/consent/${patient['patient_identifier']}`);
                //     return
                // }
                // Patient Existing Create a Consult
                // Recycle.rpc({
                //     model: "BookingController",
                //     method: "update_booking_status",
                //     args: ["arrived", manual_booking_res.booking_id]
                // }).then((res) => {
                //     Recycle.rpc({
                //         model: "ConsultController",
                //         method: "create_consult",
                //         args: [appConfig.user.dsp, manual_booking_res.booking_id, patient.identifier_type, patient.identifier]
                //     }).then((res) => {
                //         enqueueSnackbar("Consult Created", {variant: "success"});
                //         history.push('/dashboard');
                //
                //     }).catch((err) => {
                //     })
                // })
            }
        }).catch(err => {
            console.log(err.response);
        });
    }
    return (
        <div>
            <Typography variant="h3" className={classes.title}>
                Create Booking
            </Typography>
            <h1></h1>
            {showPatientDetails ? renderPatientForm() : renderSearchForm()}
        </div>
    );
};

export default ManualBookingForm;

const useStyles = makeStyles(theme => ({
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(2)
    },
    updateButton: {
        marginRight: theme.spacing(2)
    },
    title: {
        marginLeft: theme.spacing(1),
    },
}));

let manual_booking_details = {
    "id": "PATIENT_SEARCH",
    "name": "Search Patient",
    "groups": [
        {
            "id": "part_1",
            "name": "Enter Booking Details",
            "fields": [
                {
                    "id": "first_name",
                    "name": "First Name",
                    "type": "text",
                    "default": "None",
                    "optional": false,
                    "display_view": "text"
                },
                {
                    "id": "last_name",
                    "name": "Last Name",
                    "type": "text",
                    "default": "None",
                    "optional": false,
                    "display_view": "text"
                },
                {
                    "id": "contact_number",
                    "name": "Contact Number",
                    "type": "text",
                    "default": "None",
                    "validation": ["valid_sa_phone_number", "get", "contact_number"],
                    "optional": false,
                    "display_view": "text"
                },
                {
                    "id": "email",
                    "name": "Email",
                    "type": "text",
                    "default": "None",
                    "validation": [
                        "|",
                        "regex",
                        "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$",
                        "get", "email",
                        "!",
                        "get", "email"
                    ],
                    "optional": true,
                    "display_view": "text"
                },
                {
                    "id": "gender",
                    "name": "Gender",
                    "type": "radio",
                    "default": "None",
                    "optional": false,
                    "display_view": "radio",
                    "options": [
                        {
                            "id": "male",
                            "name": "Male"
                        },
                        {
                            "id": "female",
                            "name": "Female"
                        }
                    ]
                },
                {
                    "id": "date_of_birth",
                    "name": "Date of Birth",
                    "type": "date",
                    "default": "1990/01/01",
                    "optional": false,
                    "display_view": "text",
                    "validation": [
                        "!=",
                        "get", "date_of_birth",
                        "undefined"
                    ],
                    "render_condition": ["=", "get", "identifier_type", "PASSPORT"],
                    "calculation_precondition": [],
                    "calculation": [],
                    "options": []
                },
                {
                    "id": "identifier_type",
                    "name": "Identity Type",
                    "type": "radio",
                    "default": "None",
                    "optional": false,
                    "display_view": "radio",
                    "options": [
                        {
                            "id": "SAID",
                            "name": "ID Number"
                        },
                        {
                            "id": "PASSPORT",
                            "name": "Passport Number"
                        }
                    ]
                },
                {
                    "id": "identifier",
                    "name": "ID Number",
                    "type": "text",
                    "default": "None",
                    "optional": false,
                    "display_view": "text",
                    "validation": ["valid_said", "get", "identifier"],
                    "render_condition": ["=", "get", "identifier_type", "SAID"],
                    "calculation_precondition": [],
                    "calculation": [],
                    "options": []
                },
                {
                    "id": "identifier",
                    "name": "Passport Number",
                    "type": "text",
                    "default": "None",
                    "optional": false,
                    "display_view": "text",
                    "render_condition": ["=", "get", "identifier_type", "PASSPORT"],
                    "calculation_precondition": [],
                    "calculation": [],
                    "options": []
                },
                {
                    "id": "service", // Keep this field last on the list
                    "name": "Service",
                    "type": "drop_down",
                    "default": "PHC",
                    "optional": false,
                    "display_view": "drop_down",
                    "options": []
                }
            ]
        }
    ]
}

const searchForm = {
    "id": "PATIENT_SEARCH",
    "name": "Search Patient",
    "groups": [
        {
            "id": "part_1",
            "name": "Personal Particulars of Patient",
            "fields": [
                {
                    "id": "identifier_type",
                    "name": "Identity Type",
                    "type": "radio",
                    "default": "None",
                    "optional": false,
                    "display_view": "radio",
                    "render_condition": [],
                    "calculation_precondition": [],
                    "calculation": [],
                    "options": [
                        {
                            "id": "SAID",
                            "name": "ID Number"
                        },
                        {
                            "id": "PASSPORT",
                            "name": "Passport Number"
                        }
                    ]
                },
                {
                    "id": "identifier",
                    "name": "ID Number",
                    "type": "text",
                    "default": "None",
                    "optional": false,
                    "display_view": "text",
                    "validation": [
                        "valid_said",
                        "get", "identifier"
                    ],
                    "render_condition": ["=", "get", "identifier_type", "SAID"],
                    "calculation_precondition": [],
                    "calculation": [],
                    "options": []
                },
                {
                    "id": "identifier",
                    "name": "Passport Number",
                    "type": "text",
                    "default": "None",
                    "optional": false,
                    "display_view": "text",
                    "render_condition": ["=", "get", "identifier_type", "PASSPORT"],
                    "calculation_precondition": [],
                    "calculation": [],
                    "options": []
                }
            ]
        }
    ]
}
