import React, { useState } from 'react';
import DynamicForm from '../DynamicForm';
import { sharedStyles } from '../../shared/styles';
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Recycle from "../../Recycle";
import { useHistory } from 'react-router-dom';
import { Button } from "@material-ui/core";
import { useSnackbar } from "notistack";
import ConfirmDialog from "../ConfirmDialog";

const ProcedureDetails = ({ config, props: { procedure, consult_id, consult, procedure_values }, refresh }) => {
    const history = useHistory();
    const classes = useStyles();
    const sharedClasses = sharedStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [showDialog, setShowDialog] = useState(false);

    const onSubmit = async (values) => {
        const version = procedure_values.version || 0;
        const draftVersion = procedure_values.draft_version || 0;

        await Recycle.rpc({
            model: 'ProcedureController',
            method: 'save_procedure_details',
            args: [consult_id, procedure.id, values, version, draftVersion]
        }).then(res => {
            enqueueSnackbar(`Saved ${procedure.name}`, { variant: "success" })
            history.push(`/consults/${consult_id}`)
        }).catch(err => {
            if (err.response?.status === 409) {
                setShowDialog(true);
                return;
            }
            enqueueSnackbar(`Failed to save  ${procedure.name}!`, { variant: "error" })
            if (err.response?.data?.message) {
                enqueueSnackbar(err.response?.data?.message, { variant: "error" })
            }
            console.log(err.response);
        });
    }

    const onSaveDraft = async (values) => {
        const version = procedure_values.draft_version || 0;

        await Recycle.rpc({
            model: 'ProcedureController',
            method: 'save_procedure_details_draft',
            args: [consult_id, procedure.id, values, version]
        }).then(res => {
            enqueueSnackbar(`Saved ${procedure.name} draft`, { variant: "success" })
            history.push(`/consults/${consult_id}`)
        }).catch(err => {
            if (err.response?.status === 409) {
                setShowDialog(true);
                return;
            }
            enqueueSnackbar(`Failed to save  ${procedure.name} draft!`, { variant: "error" })
            if (err.response?.data?.message) {
                enqueueSnackbar(err.response?.data?.message, { variant: "error" })
            }
            console.log(err.response);
        })
    }

    const onCloseDialog = () => {
        setShowDialog(false);
        refresh();
    }

    return (
        <React.Fragment>
            <Paper>
                <div className={classes.container}>
                    <div className={sharedClasses.heading}>{procedure.name}</div>
                    <Divider className={classes.divider} variant="middle" />
                    <DynamicForm
                        onSubmit={onSubmit}
                        config={{
                            fields: procedure,
                            defaultValues: procedure_values.values || {}
                        }}
                        extraValues={{ patient: consult.patient }}
                        actions={[
                            ({ submitting }) => <Button
                                key="cancel"
                                disabled={submitting}
                                onClick={() => history.push(`/consults/${consult_id}`)}
                                variant="outlined">
                                Cancel
                        </Button>,
                            ({ submitting, getValues }) => procedure_values.is_draft !== false ? <Button
                                key="save_draft"
                                variant="contained"
                                disabled={submitting}
                                onClick={() => onSaveDraft(getValues())}>
                                Save draft
                        </Button> : null,
                            ({ submitting }) => (<Button
                                key="save"
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={submitting}
                            >
                                Save
                            </Button>)
                        ]}
                    />
                </div>
            </Paper>

            <ConfirmDialog
                open={showDialog}
                title="Note"
                message="Your procedure form data is outdated, this may be due to another clinician capturing data, the page will now refresh"
                onClose={onCloseDialog}
            />
        </React.Fragment>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 15
    },
    heading: {
        color: theme.palette.primary.main,
        fontSize: '16px',
        fontWeight: 'bold',
        marginBottom: theme.spacing(3)
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
}));

export default ProcedureDetails;