import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InfoText from './InfoText';
import { calculateAge } from '../shared/utils';

const PatientDetails = ({ config, props }) => {
    const classes = useStyles();

    return (
        <div>
            <div className={classes.heading}>Patient Details</div>
            <div className={classes.gridContainer}>
                <InfoText placeholder="First Name" text={props.patient.first_name} />
                <InfoText placeholder="Last Name" text={props.patient.last_name} />
                <InfoText placeholder="Age" text={calculateAge(props.patient.date_of_birth)} />
                <InfoText placeholder="Gender" text={props.patient.gender} />
                <InfoText placeholder="Contact Number" text={props.patient.contact_number} />
                <InfoText placeholder="Email" text={props.patient.email} />
            </div>
        </div>

    )
}

const useStyles = makeStyles(theme => ({
    gridContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: `${theme.spacing(3)}px`
    },
    heading: {
        color: theme.palette.primary.main,
        fontSize: '16px',
        fontWeight: 'bold',
        marginBottom: theme.spacing(3)
    },
}));

export default PatientDetails;