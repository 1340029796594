import React from 'react';
import AdjustStock from "../StockManagement/AdjustStock";
import {getCurrentStockItems, getStockMoves, recordDamages, saveStockTake} from "../../StockManagerConfig"
import TransferStockToAnotherClinic from "../StockManagement/TransferStockToAnotherClinic";

const AdjustStockBase = ({ config, props }) => {
    return (
        <>
            <AdjustStock
                getStockMoves={getStockMoves}
                recordDamages={recordDamages}
            />
        </>

    )
}

export default AdjustStockBase;